import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Loading from '../components/Loading';
import SettingForm from '../components/SettingForm';

import { updateVoucher, updateMechanic, updateRegisterVoucher, updateBirthdayVoucher } from '../../../redux/actions/adminActions';

import axios from 'axios';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { currency } from '../../../dynamicController';
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class VoucherSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            type: "Register",
            washerSetting: null,
            dryerSetting: null,
            combinedSetting: null,
            combined: false,
            specificStamp: "",
            stampTransactionAmount: 0,
            stamp: false,
            payAndGetRegVoucher: false,
            regVoucherAmount: 1,
            reg_voucher_method: "Register",
            payAndGetBirthdayVoucher: false,
            birthdayVoucherAmount: 1
        };
    };

    componentDidMount() {
        if (!this.props.admin.isAuthenticated || !this.props.admin.admin || !this.props.admin.admin.voucher_setting) {
            return this.props.history.push('/admin/login');
        } else {
            if (this.state.type === "Stamp") {
                const washerVoucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === "Washer Stamp")[0]
                const dryerVoucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === "Dryer Stamp")[0]
                const combinedVoucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === "Combined Stamp")[0]
                this.setState({
                    ...this.state,
                    washerSetting: washerVoucherSetting,
                    dryerSetting: dryerVoucherSetting,
                    combinedSetting: combinedVoucherSetting,
                    loading: false,
                    combined: this.props.admin.admin.combined,
                    specificStamp: this.props.admin.admin.specificStamp,
                    stampTransactionAmount: this.props.admin.admin.stampTransactionAmount,
                    stamp: this.props.admin.admin.stamp,
                    payAndGetRegVoucher: this.props.admin.admin.payAndGetRegVoucher,
                    regVoucherAmount: this.props.admin.admin.regVoucherAmount,
                    reg_voucher_method: this.props.admin.admin.reg_voucher_method,
                    payAndGetBirthdayVoucher: this.props.admin.admin.payAndGetBirthdayVoucher,
                    birthdayVoucherAmount: this.props.admin.admin.birthdayVoucherAmount
                });
            } else {
                const voucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === this.state.type)[0]
                this.setState({
                    ...this.state,
                    washerSetting: null,
                    dryerSetting: null,
                    combinedSetting: voucherSetting,
                    loading: false,
                    combined: this.props.admin.admin.combined,
                    specificStamp: this.props.admin.admin.specificStamp,
                    stampTransactionAmount: this.props.admin.admin.stampTransactionAmount,
                    stamp: this.props.admin.admin.stamp,
                    payAndGetRegVoucher: this.props.admin.admin.payAndGetRegVoucher,
                    regVoucherAmount: this.props.admin.admin.regVoucherAmount,
                    reg_voucher_method: this.props.admin.admin.reg_voucher_method,
                    payAndGetBirthdayVoucher: this.props.admin.admin.payAndGetBirthdayVoucher,
                    birthdayVoucherAmount: this.props.admin.admin.birthdayVoucherAmount
                });
            }
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleSelect = val => {
        if (this.state.type !== val) {
            if (val === "Stamp") {
                const washerVoucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === "Washer Stamp")[0]
                const dryerVoucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === "Dryer Stamp")[0]
                const combinedVoucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === "Combined Stamp")[0]
                this.setState({
                    ...this.state,
                    type: val,
                    washerSetting: washerVoucherSetting,
                    dryerSetting: dryerVoucherSetting,
                    combinedSetting: combinedVoucherSetting,
                    loading: false,
                    combined: this.props.admin.admin.combined,
                    specificStamp: this.props.admin.admin.specificStamp,
                    stampTransactionAmount: this.props.admin.admin.stampTransactionAmount,
                    stamp: this.props.admin.admin.stamp,
                    payAndGetRegVoucher: this.props.admin.admin.payAndGetRegVoucher,
                    regVoucherAmount: this.props.admin.admin.regVoucherAmount,
                    reg_voucher_method: this.props.admin.admin.reg_voucher_method,
                    payAndGetBirthdayVoucher: this.props.admin.admin.payAndGetBirthdayVoucher,
                    birthdayVoucherAmount: this.props.admin.admin.birthdayVoucherAmount
                });
            } else {
                const voucherSetting = this.props.admin.admin.setting.filter(setting => setting.type === val)[0]
                this.setState({
                    ...this.state,
                    type: val,
                    washerSetting: null,
                    dryerSetting: null,
                    combinedSetting: voucherSetting,
                    loading: false,
                    combined: this.props.admin.admin.combined,
                    specificStamp: this.props.admin.admin.specificStamp,
                    stampTransactionAmount: this.props.admin.admin.stampTransactionAmount,
                    stamp: this.props.admin.admin.stamp,
                    payAndGetRegVoucher: this.props.admin.admin.payAndGetRegVoucher,
                    regVoucherAmount: this.props.admin.admin.regVoucherAmount,
                    reg_voucher_method: this.props.admin.admin.reg_voucher_method,
                    payAndGetBirthdayVoucher: this.props.admin.admin.payAndGetBirthdayVoucher,
                    birthdayVoucherAmount: this.props.admin.admin.birthdayVoucherAmount
                });
            }
        }
    };

    handleCheckbox = (field, target) => {

        this.setState({
            ...this.state,
            [field]: {
                ...this.state[field],
                [target.id]: !this.state[field][target.id]
            }
        });
    };

    handleChange = (field, target) => {

        this.setState({
            ...this.state,
            [field]: {
                ...this.state[field],
                [target.id]: target.value
            }
        });
    };

    handleStamp = e => this.setState({
        ...this.state,
        stamp: !this.state.stamp
    });

    handleCombined = e => this.setState({
        ...this.state,
        combined: !this.state.combined
    });

    handlePayAndGetRegVoucher = e => this.setState({
        ...this.state,
        payAndGetRegVoucher: !this.state.payAndGetRegVoucher
    });

    handleRegVoucherMethod = e => this.setState({
        ...this.state,
        reg_voucher_method: e.target.value
    });

    handlePayAndGetBirthdayVoucher = e => this.setState({
        ...this.state,
        payAndGetBirthdayVoucher: !this.state.payAndGetBirthdayVoucher
    });

    submitCombined = e => {
        e.preventDefault();
        if (this.props.admin.admin.stamp !== this.state.stamp || this.props.admin.admin.combined !== this.state.combined || this.props.admin.admin.specificStamp !== this.state.specificStamp || this.props.admin.admin.stampTransactionAmount !== this.state.stampTransactionAmount) {
            mySwal.fire({
                title: i18n.t("Confirmation"),
                text: i18n.t("Are you sure you want to change how the stamping mechanics works?"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: i18n.t("No"),
                confirmButtonText: i18n.t("Yes"),
                cancelButtonColor: "#d33"
            })
                .then(click => {
                    if (click.isConfirmed && click.value) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });

                        const operatorId = this.props.admin.admin.operatorId;
                        const { combined, specificStamp, stampTransactionAmount, stamp } = this.state;

                        const sendThis = {
                            id: operatorId,
                            role: "operator",
                            combined,
                            specificStamp,
                            stampTransactionAmount,
                            stamp,
                        };

                        axios.post('/api/admin/setting/setCombined', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                            .then(res => {
                                this.props.updateMechanic(res.data);
                                this.setState({
                                    ...this.state,
                                    loading: false,
                                    combined: res.data.combined,
                                    specificStamp: res.data.specificStamp,
                                    stampTransactionAmount: res.data.stampTransactionAmount,
                                    stamp: res.data.stamp,
                                });
                                mySwal.fire(i18n.t("Success"), i18n.t("The stamping mechanics setting has updated successfully!"), "success");
                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                            });
                    }
                })
        }
    };

    handleSpecific = e => this.setState({
        ...this.state,
        specificStamp: e.target.value
    });

    handleStampTransactionAmount = e => this.setState({
        ...this.state,
        stampTransactionAmount: e.target.value
    });

    handleRegVoucherAmount = e => this.setState({
        ...this.state,
        regVoucherAmount: e.target.value
    });

    handleBirthdayVoucherAmount = e => this.setState({
        ...this.state,
        birthdayVoucherAmount: e.target.value
    });

    handleSubmit = e => {
        e.preventDefault();
        const { type, washerSetting, dryerSetting, combinedSetting, payAndGetRegVoucher, regVoucherAmount, reg_voucher_method, payAndGetBirthdayVoucher, birthdayVoucherAmount } = this.state;
        const { operatorId } = this.props.admin.admin;

        const sendThis = {
            id: operatorId,
            role: "operator",
            type,
            washerSetting,
            dryerSetting,
            combinedSetting,
            payAndGetRegVoucher,
            regVoucherAmount,
            reg_voucher_method,
            payAndGetBirthdayVoucher,
            birthdayVoucherAmount
        };

        this.setState({
            ...this.state,
            loading: true
        });

        axios.post('/api/admin/setting/update', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false
                })
                this.props.updateVoucher(res.data.data);

                if (type === "Register") {
                    this.props.updateRegisterVoucher({ payAndGetRegVoucher, regVoucherAmount, reg_voucher_method })
                } else if (type === "Birthday") {
                    this.props.updateBirthdayVoucher({ payAndGetBirthdayVoucher, birthdayVoucherAmount })
                }

                mySwal.fire({
                    title: i18n.t("Success"),
                    text: i18n.t("The voucher setting has been updated successfully!"),
                    icon: "success",
                    // confirmButtonColor: "#ffd109"
                });
            })
            .catch(err => {
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    };


    render() {
        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Mechanics Settings")}</h5>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.submitCombined}>

                                    <div className="form-group">
                                        <label htmlFor="stamp">
                                            <input type="checkbox" id="stamp" name="stamp" checked={this.state.stamp} onChange={this.handleStamp} />
                                            <span>{i18n.t("Enable Stamping")}</span>
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="stampTransactionAmount">{i18n.t("Minimum transaction amount for getting stamp")} : ({currency(this.props.admin.admin?.country)})</label>
                                        <input type="number" id="stampTransactionAmount" name="stampTransactionAmount" value={this.state.stampTransactionAmount} onChange={this.handleStampTransactionAmount} min="0" step="any" required />
                                    </div>

                                    <div className="form-group">
                                        <p>{i18n.t("Select User Stamping Mechanic")}</p>

                                        <p>
                                            <label htmlFor="radio1">
                                                <input type="radio" name="combined" id="radio1" className="with-gap" checked={this.state.combined === false} onChange={this.handleCombined} />
                                                <span>{i18n.t("Separated Stamping")}</span>
                                            </label>
                                        </p>

                                        <p>
                                            <label htmlFor="radio2">
                                                <input type="radio" name="combined" id="radio2" className="with-gap" checked={this.state.combined === true} onChange={this.handleCombined} />
                                                <span>{i18n.t("Combined Stamping")}</span>
                                            </label>
                                        </p>
                                    </div>

                                    {
                                        !this.state.combined
                                            ? (
                                                <div>
                                                    <p>{i18n.t("Stamp for?")}</p>
                                                    <div className="d-flex">
                                                        <div className="form-group">
                                                            <label htmlFor="both">
                                                                <input type="checkbox" id="both" name="specificStamp" checked={this.state.specificStamp === "both"} value="both" onChange={this.handleSpecific} />
                                                                <span>{i18n.t("Both")}</span>
                                                            </label>
                                                        </div>

                                                        <div className="form-group ms-5">
                                                            <label htmlFor="washerOnly">
                                                                <input type="checkbox" id="washerOnly" name="specificStamp" checked={this.state.specificStamp === "washerOnly"} value="washerOnly" onChange={this.handleSpecific} />
                                                                <span>{i18n.t("Washer Only")}</span>
                                                            </label>
                                                        </div>

                                                        <div className="form-group ms-5">
                                                            <label htmlFor="dryerOnly">
                                                                <input type="checkbox" id="dryerOnly" name="specificStamp" checked={this.state.specificStamp === "dryerOnly"} value="dryerOnly" onChange={this.handleSpecific} />
                                                                <span>{i18n.t("Dryer Only")}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }

                                    <div>
                                        {
                                            this.state.loading
                                                ? (
                                                    <div className="text-center">
                                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                                : <input type="submit" value={i18n.t("SUBMIT")} className="btn btn-block btn-warning big-button" disabled={this.props.admin.admin.combined === this.state.combined && this.props.admin.admin.specificStamp === this.state.specificStamp && this.props.admin.admin.stampTransactionAmount === this.state.stampTransactionAmount && this.props.admin.admin.stamp === this.state.stamp} />
                                        }
                                    </div>
                                </form>
                                <div className="mt-5">
                                    <p><small>* {i18n.t("Separated Stamping = Different washer and dryer stamp collection. (example: Using Washer will gain 1 washer's stamp, and Dryer will gain 1 dryer stamp)")}</small></p>
                                    <p><small>* {i18n.t("Combined Stamping = Washer and dryer has the same collection. (example: Using Washer or Dryer will gain 1 stamp)")}</small></p>
                                </div>
                                {
                                    !this.state.combined
                                        ? (
                                            <div>
                                                <p><small>{i18n.t("Stamp for")} : <strong>{this.state.specificStamp === "both" ? i18n.t("Both") : this.state.specificStamp === "washerOnly" ? i18n.t("Washer Only") : i18n.t("Dryer Only")}</strong></small></p>
                                                <p><small>* {this.state.specificStamp === "both" ? i18n.t("User will be able to collect both washer stamp and dryer stamp") : i18n.t(`User will only able to collect ${this.state.specificStamp === "washerOnly" ? "Washer" : "Dryer"} stamp`)}</small></p>
                                            </div>
                                        )
                                        : null
                                }
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Voucher Settings")}</h5>
                            </div>

                            <div className="card-body">
                                <div>
                                    <ul className="nav nav-tabs m-auto">
                                        <li className="nav-item">
                                            <button onClick={e => this.handleSelect("Register")} className={this.state.type === "Register" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: this.state.type === "Register" ? null : "1px solid #dee2e6" }}>
                                                {i18n.t("Registration")}
                                            </button>
                                        </li>

                                        {
                                            this.props.admin.admin?.stamp ? (
                                                <li className="nav-item">
                                                    <button onClick={e => this.handleSelect("Stamp")} className={this.state.type === "Stamp" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: this.state.type === "Stamp" ? null : "1px solid #dee2e6" }}>
                                                        {i18n.t("Stamp")}
                                                    </button>
                                                </li>
                                            ) : null
                                        }

                                        <li className="nav-item">
                                            <button onClick={e => this.handleSelect("Birthday")} className={this.state.type === "Birthday" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: this.state.type === "Birthday" ? null : "1px solid #dee2e6" }}>
                                                {i18n.t("Birthday")}
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="mt-5">
                                        {
                                            this.state.loading
                                                ? <Loading />
                                                : <SettingForm
                                                    selected={this.state.type}
                                                    washerSetting={this.state.washerSetting}
                                                    dryerSetting={this.state.dryerSetting}
                                                    combinedSetting={this.state.combinedSetting}
                                                    handleCheckbox={this.handleCheckbox}
                                                    handleChange={this.handleChange}
                                                    handleSubmit={this.handleSubmit}
                                                    combined={this.props.admin.admin.combined}
                                                    payAndGetRegVoucher={this.state.payAndGetRegVoucher}
                                                    regVoucherAmount={this.state.regVoucherAmount}
                                                    reg_voucher_method={this.state.reg_voucher_method}
                                                    handlePayAndGetRegVoucher={this.handlePayAndGetRegVoucher}
                                                    handleRegVoucherAmount={this.handleRegVoucherAmount}
                                                    handleRegVoucherMethod={this.handleRegVoucherMethod}
                                                    payAndGetBirthdayVoucher={this.state.payAndGetBirthdayVoucher}
                                                    birthdayVoucherAmount={this.state.birthdayVoucherAmount}
                                                    handlePayAndGetBirthdayVoucher={this.handlePayAndGetBirthdayVoucher}
                                                    handleBirthdayVoucherAmount={this.handleBirthdayVoucherAmount}
                                                    country={this.props.admin.admin.country}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateVoucher: data => dispatch(updateVoucher(data)),
        updateMechanic: data => dispatch(updateMechanic(data)),
        updateRegisterVoucher: data => dispatch(updateRegisterVoucher(data)),
        updateBirthdayVoucher: data => dispatch(updateBirthdayVoucher(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherSetting);