import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import LaundryPassCard from "../../admin/components/setting/LaundryPassCard";

const LaundryPass = ({ token, client }) => {

    const history = useHistory();

    const { t } = useTranslation();

    const [toggled, setToggled] = useState(false);
    const [distributorId, setDistributorId] = useState("");
    const [country, setCountry] = useState("Malaysia");
    const [outletList, setOutletList] = useState([])

    useEffect(() => {
        if (!client || !client.laundry_pass) {
            history.push('/client')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const handleSelectDistributor = (e) => {
        setDistributorId(e.target.value);
        setCountry(client.distributor.filter(distributor => distributor.id === e.target.value)[0].country)
        setOutletList(client.outlet.filter(outlet => outlet.distributorId === e.target.value))
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />
            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <div className="text-center">
                        <h3>{t("Laundry Pass")}</h3>
                    </div>

                    <div className="pb-2">
                        <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                        <select id="distributorId" name="distributorId" className="browser-default form-select" value={distributorId} onChange={e => handleSelectDistributor(e)}>
                            <option disabled value="">{t("Select Distributor")}</option>
                            {
                                client.distributor.map(distributor => <option key={distributor.id} value={distributor.id}>{distributor.name}</option>)
                            }
                        </select>
                    </div>

                    {
                        distributorId ? (
                            <LaundryPassCard 
                                id={distributorId}
                                role="distributor"
                                token={token}
                                country={country}
                                outlets={outletList}
                            />
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(LaundryPass);