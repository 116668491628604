import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import VendingPaymentLogo from '../../../../assets/images/machines/vendingMachine.png';
import { currency } from '../../../../dynamicController';

const LaundroVending = ({ setPage, machine, amount, minusAmount, addAmount, outlet, user, setSendThis, setShowOverlay, setEndpoint }) => {
    const { t } = useTranslation();
    const [showConfirmPayment, setShowConfirmPayment] = useState(false);

    const confirmPayment = () => {
        let sendThis = {
            amount,
            machine,
            paymentAmount: amount,
            outlet,
            kioskv2: true,
        };
        let endPoint = '/api/nonuser/payment';

        if (user) {
            sendThis.memberId = user.memberId;
            endPoint = '/api/user/payment';
        }

        setSendThis(sendThis);
        setEndpoint(endPoint);
        setPage('Payment');
    };

    const MachineIcon = () => (
        <div className="d-flex flex-column align-items-center mb-2">
            <div
                className="position-relative"
                style={{
                    width: '150px',
                    height: '150px',
                    backgroundColor: '#D3D3D3',
                    borderRadius: '10px',
                    padding: '10px',
                }}
            >
                <img
                    src={VendingPaymentLogo}
                    alt="Dryer"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    }}
                />
            </div>
            <div
                className="text-white fw-semibold"
                style={{
                    backgroundColor: '#D3D3D3',
                    marginTop: '-10px',
                    borderRadius: '0 0 10px 10px',
                    width: '150px',
                    textAlign: 'center',
                    fontSize: '1rem',
                    padding: '5px 0',
                }}
            >
                {t(machine.type)}  {machine.name.split('-')[1]}
            </div>
        </div>
    );

    const PriceTimeBubble = () => (
        <div
            className="d-flex flex-column align-items-center justify-content-center mb-2"
            style={{
                backgroundColor: '#e0f0ff',
                border: '2px solid #0000ff',
                borderRadius: '40px',
                width: '200px',
                height: '60px',
                fontSize: '1.1rem',
                color: '#000',
            }}
        >
            <span>
                {currency(machine.country)}
                {parseFloat(amount).toFixed(2)}
            </span>
        </div>
    );

    return (
        <div
            className="container-fluid position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 9999,
            }}
        >
            {!showConfirmPayment ? (
                <div className="container" style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center p-4">
                            <h2
                                className="fw-bold text-dark mb-4 mt-2"
                                style={{ fontSize: '2rem' }}
                            >
                                {t('Select the price for')}
                            </h2>

                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <MachineIcon />
                                </div>
                            </div>

                            <div className="row justify-content-center mb-3">
                                <div className="col-auto">
                                    <div
                                        className="d-flex justify-content-between align-items-center"
                                        style={{
                                            fontSize: '1.5rem',
                                            backgroundColor: '#fff',
                                            border: '2px solid #0000ff',
                                            borderRadius: '60px',
                                            width: '500px',
                                            padding: '5px 20px',
                                        }}
                                    >
                                        <button
                                            onClick={minusAmount}
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                                fontSize: '2rem',
                                                fontWeight: 'bold',
                                                color: '#0000ff',
                                            }}
                                        >
                                            -
                                        </button>
                                        <div className="text-center">
                                            {currency(machine.country)}
                                            {parseFloat(amount).toFixed(2)}
                                        </div>
                                        <button
                                            onClick={addAmount}
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                                fontSize: '2rem',
                                                fontWeight: 'bold',
                                                color: '#0000ff',
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-auto d-flex" style={{ gap: '1rem' }}>
                                    <div
                                        className="position-relative"
                                        style={{ width: '130px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={YesButton}
                                            alt="Proceed Button"
                                            className="img-fluid"
                                            onClick={() => setShowConfirmPayment(true)}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-semibold"
                                            style={{
                                                fontSize: '1rem',
                                                color: '#fff',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {t('Proceed')}
                                        </span>
                                    </div>

                                    <div
                                        className="position-relative"
                                        style={{ width: '130px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={NoButton}
                                            alt="Cancel Button"
                                            className="img-fluid"
                                            onClick={() => setShowOverlay(false)}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-semibold"
                                            style={{
                                                fontSize: '1rem',
                                                color: '#fff',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {t('Cancel')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container" style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center p-4">
                            <h2
                                className="fw-bold text-dark mb-4"
                                style={{ fontSize: '2rem' }}
                            >
                                {t('Confirm Payment')}
                            </h2>

                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <MachineIcon />
                                </div>
                            </div>

                            <div className="row justify-content-center mb-4">
                                <div className="col-auto">
                                    <PriceTimeBubble bigger />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-auto d-flex" style={{ gap: '1rem' }}>
                                    <div
                                        className="position-relative"
                                        style={{ width: '130px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={YesButton}
                                            alt="Pay Button"
                                            className="img-fluid"
                                            onClick={confirmPayment}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-semibold"
                                            style={{
                                                fontSize: '1rem',
                                                color: '#fff',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {t('Pay')}
                                        </span>
                                    </div>

                                    <div
                                        className="position-relative"
                                        style={{ width: '130px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={NoButton}
                                            alt="Cancel Button"
                                            className="img-fluid"
                                            onClick={() => setShowConfirmPayment(false)}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-semibold"
                                            style={{
                                                fontSize: '1rem',
                                                color: '#fff',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {t('Cancel')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LaundroVending;