import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProgressBar } from 'react-bootstrap';

// cleanpro icon
import dryerIcon from "../../../../assets/images/machines/icn-dryer.png";
import washerIcon from "../../../../assets/images/machines/icn-washer.png";
import cleanproLaundryPass from "../../../../assets/images/news/cleanproLaundryPass.jpeg";

// washup icon
import washupWasher from "../../../../assets/images/machines/washup-washer.png";
import washupDryer from "../../../../assets/images/machines/washup-dryer.png";

// laundro icon
import laundroWasher from "../../../../assets/images/machines/paymentWasher.png";
import laundroDryer from "../../../../assets/images/machines/paymentDryer.png";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const LaundryPass = ({ user }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div>
            {
                user.subscriptions.length > 0 ? (
                    <div className={STORETYPE === "washup" || STORETYPE === "cleanpro" ? "stamp-component-cp" : "shadow px-5 py-4 mt-4 mb-3"}>
                        <div className="d-flex">
                            <div>
                                <p style={{ fontSize: "18px", fontWeight: 500 }}>{t("Laundry Pass")}</p>
                                {user.subscriptions[0].expired_date ? <p>{t("Expired on")} {new Date(user.subscriptions[0].expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p> : null}
                            </div>
                        </div>

                        <div>
                            {
                                user.currentSubscription[0].wash > 0 ? (
                                    <div className="py-3">
                                        <div className="d-flex">
                                            <img src={STORETYPE === "cleanpro" ? washerIcon : STORETYPE === "washup" ? washupWasher : laundroWasher} style={{ width: "40px", padding: "3px" }} alt="" />
                                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.currentSubscription[0].available_wash}/{user.currentSubscription[0].wash}</p>
                                        </div>
                                        {
                                            STORETYPE === "washup" || STORETYPE === "cleanpro"
                                                ? <progress id="progress-bar" value={user.currentSubscription[0].available_wash} max={user.currentSubscription[0].wash}></progress>
                                                : <ProgressBar now={(parseInt(user.currentSubscription[0].available_wash) / parseInt(user.currentSubscription[0].wash)) * 100} />
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                user.currentSubscription[0].dry > 0 ? (
                                    <div className="py-3">
                                        <div className="d-flex">
                                            <img src={STORETYPE === "cleanpro" ? dryerIcon : STORETYPE === "washup" ? washupDryer : laundroDryer} style={{ width: "40px" }} alt="" />
                                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.currentSubscription[0].available_dry}/{user.currentSubscription[0].dry}</p>
                                        </div>
                                        {
                                            STORETYPE === "washup" || STORETYPE === "cleanpro"
                                                ? <progress id="progress-bar" value={user.currentSubscription[0].available_dry} max={user.currentSubscription[0].dry}></progress>
                                                : <ProgressBar now={(parseInt(user.currentSubscription[0].available_dry) / parseInt(user.currentSubscription[0].dry)) * 100} />
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                ) : STORETYPE === "cleanpro" ? (
                    <div style={{ padding: "5vw" }} onClick={() => history.push("/user/news/cleanproLaundryPass")}>
                        <img src={cleanproLaundryPass} alt="laundrypass" />
                    </div>
                ) : null
            }
        </div>
    )
}

export default LaundryPass;