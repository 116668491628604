import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { currency, timezone } from "../../../dynamicController";
import Navigator from "./Navigation";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const TransDetails = ({ user }) => {
  const history = useHistory();
  const location = useLocation();
  const [transaction, setTransaction] = useState(null);
  const [reload, setReload] = useState(null);
  const [transfer, setTransfer] = useState(null);
  const [point, setPoint] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!location.state || !user) {
      history.push('/')
    } else if (location.state.transaction) {
      setTransaction(location.state.transaction)
    } else if (location.state.reload) {
      setReload(location.state.reload)
    } else if (location.state.transfer) {
      setTransfer(location.state.transfer)
    } else if (location.state.point) {
      setPoint(location.state.point)
    } else if (location.state.subscription) {
      setSubscription(location.state.subscription)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderDate = (date, filter) => {
    const ascMonth = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const thisDate = new Date(date);

    let hour24 = "";
    let hour = "";
    let offset = parseInt(timezone(user.country))

    if (thisDate.getUTCHours() + offset >= 24) {
      hour24 = thisDate.getUTCHours() + offset - 24;
    } else if (thisDate.getUTCHours() + offset < 0) {
      hour24 = thisDate.getUTCHours() + offset + 24;
    } else {
      hour24 = thisDate.getUTCHours() + offset;
    }

    if (hour24 > 12) {
      hour = hour24 - 12;
      if (hour.toString().length === 1) {
        hour = `0${hour}`;
      }
    } else {
      hour = hour24;
      if (hour.toString().length === 1) {
        hour = `0${hour}`;
      }
    }

    const minute =
      thisDate.getUTCMinutes().toString().length === 1
        ? `0${thisDate.getUTCMinutes()}`
        : thisDate.getUTCMinutes().toString();

    const day =
      thisDate.getDate().toString().length === 1
        ? `0${thisDate.getDate()}`
        : thisDate.getDate().toString();

    const month = ascMonth[thisDate.getMonth()];

    const year = thisDate.getFullYear();
    if (filter) {
      return `${month} ${day}`;
    } else {
      return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"}, ${day} ${month} ${year}`;
    }
  };

  return (
    <div id="trans-details-page">
      <div id="user-header">
        <h5>
          <strong onClick={() => history.goBack()}>
            <i className="bi bi-chevron-left me-3"></i> {t("Back")}
          </strong>
        </h5>
      </div>
      <div className="px-4 py-4 payment-section">
        <h1 className="font-weight-bold">
          {transaction ? transaction.method === "TOKEN" ? `-${transaction.payment_amount + transaction.sms_amount} ${STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN")}` : `${currency(user.country)}${transaction.payment_amount + transaction.sms_amount}` : null}
          {reload ? `+${reload.token} ${STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN")}` : null}
          {transfer ? transfer.amount > 0 ? `+${transfer.amount} ${STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN")}` : `${transfer.amount} ${STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN")}` : null}
          {point ? `${point.amount} ${user.loyalty_point_name}` : null}
          {subscription ? `${currency(user.country)}${subscription.price}` : null}
        </h1>
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Total")}</p>
          {transaction ? transaction.method === "TOKEN" ? <p>{transaction.amount} {STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN")}</p> : <p>{currency(user.country)} {transaction.amount}</p> : null}
          {reload ? <p>{currency(user.country)} {reload.amount}</p> : null}
          {transfer ? transfer.amount > 0 ? <p>+{transfer.amount} {STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN")}</p> : <p>{transfer.amount} {STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN")}</p> : null}
          {point ? `${point.amount} ${user.loyalty_point_name}` : null}
          {subscription ? <p>{currency(user.country)} {subscription.price}</p> : null}
        </div>
        {
          transaction && transaction.sms_amount ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">SMS</p>
              <p>{transaction.method === "TOKEN" ? "" : currency(user.country)} {transaction.sms_amount} {transaction.method === "TOKEN" ? STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN") : ""}</p>
            </div>
          ) : null
        }
        {
          transaction && transaction.voucher ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Voucher")}</p>
              {
                transaction.voucher.type === "Flat"
                  ? <p>-{transaction.method === "TOKEN" ? "" : currency(user.country)} {transaction.voucher.amount} {transaction.method === "TOKEN" ? STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN") : ""}</p>
                  : <p>-{transaction.voucher.amount} %</p>
              }
            </div>
          ) : null
        }
        {
          transaction && transaction.discount ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Discount Code")}</p>
              {
                transaction.discount.discount_type === "Flat"
                  ? <p>-{transaction.method === "TOKEN" ? "" : currency(user.country)} {transaction.discount.discount_amount} {transaction.method === "TOKEN" ? STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN") : ""} ({transaction.discount.code})</p>
                  : <p>-{transaction.discount.discount_amount} % ({transaction.discount.code})</p>
              }
            </div>
          ) : null
        }
        {
          transaction && transaction.subscriptionId ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Laundry Pass")}</p>
              <p>-{transaction.method === "TOKEN" ? "" : currency(user.country)} {transaction.amount - transaction.payment_amount} {transaction.method === "TOKEN" ? STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN") : ""}</p>
            </div>
          ) : null
        }
        {
          transaction && !transaction.voucher && !transaction.discount && !transaction.subscriptionId && transaction.amount !== transaction.payment_amount ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Promotion")}</p>
              <p>-{transaction.method === "TOKEN" ? "" : currency(user.country)} {transaction.amount - transaction.payment_amount} {transaction.method === "TOKEN" ? STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN") : ""}</p>
            </div>
          ) : null
        }
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Transaction Type")}</p>
          <p>{transaction && transaction.transaction_type === "Package" ? t("Drop n Go Service") : transaction ? t("Transaction") : reload ? STORETYPE === "cleanpro" ? t("Credit Topup") : t("E-Token Topup") : transfer ? STORETYPE === "cleanpro" ? t("Credit Transfer") : t("E-Token Transfer") : point ? t(point.type) : subscription ? t("Laundry Pass") : "-"}</p>
        </div>
        {
          transaction && transaction.machine_name ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Machine")}</p>
              <p>{transaction.machine_name} ({transaction.machine_type})</p>
            </div>
          ) : null
        }
        {
          transaction && transaction.outlet ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Outlet")}</p>
              <p>{transaction.outlet.outlet_fullname}</p>
            </div>
          ) : null
        }
        {
          transaction || reload || subscription ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Payment Method")}</p>
              <p>{transaction ? transaction.method === "TOKEN" ? STORETYPE === "cleanpro" ? t("Credits") : t("E-TOKEN") : transaction.method : reload && reload.method ? reload.method : subscription && subscription.method ? subscription.method : "-"}</p>
            </div>
          ) : null
        }
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Date/Time")}</p>
          <p>{transaction ? renderDate(transaction.createdAt) : reload ? renderDate(reload.createdAt) : transfer ? renderDate(transfer.createdAt) : point ? renderDate(point.createdAt) : subscription ? renderDate(subscription.createdAt) : null}</p>
        </div>
        <div className="py-3 px-2 justify-content-between d-flex">
          <p className="t-light-g">{t("Reference ID")}</p>
          <p>{transaction ? transaction.id : reload ? reload.id : point && point.transactionId ? point.transactionId : point && point.tokenPurchaseHistoryId ? point.tokenPurchaseHistoryId : point && point.giveawayId ? point.giveawayId : subscription && subscription.id ? subscription.id : "-"}</p>
        </div>
        {
          transaction || reload || subscription ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Status")}</p>
              <p>{transaction ? t(transaction.status) : reload ? t(reload.status) : transfer ? t("Success") : subscription ? t(subscription.status) : "-"}</p>
            </div>
          ) : null
        }
        {
          transaction && transaction.transaction_type === "Package" && transaction.status === "Success" ? (
            <div className="text-center pt-3">
              <button className="btn btn-primary big-button" onClick={() => history.push("/user/dng/pack/details", { transaction })}>{t("Pack Details")}</button>
            </div>
          ) : null
        }
        {
          subscription ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Name")}</p>
              <p>{subscription.name}</p>
            </div>
          ) : null
        }
        {
          subscription && subscription.wash > 0 ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Number of Wash")}</p>
              <p>{subscription.wash}</p>
            </div>
          ) : null
        }
        {
          subscription && subscription.dry > 0 ? (
            <div className="py-3 px-2 justify-content-between d-flex">
              <p className="t-light-g">{t("Number of Dry")}</p>
              <p>{subscription.dry}</p>
            </div>
          ) : null
        }
      </div>
      <Navigator />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(TransDetails);
