import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import LaundryPassCard from "../components/setting/LaundryPassCard";

const LaundryPass = ({ token, admin }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false)

    useEffect(() => {
        if (!admin || !admin.laundry_pass) {
            history.push('/admin/dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />
            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <LaundryPassCard
                        id={admin?.operatorId}
                        role="operator"
                        token={token}
                        country={admin?.country}
                        outlets={admin?.outlets}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        admin: state.admin.admin,
        token: state.admin.token
    };
};

export default connect(mapStateToProps, null)(LaundryPass);