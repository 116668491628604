import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from 'antd';
import { Card } from 'react-bootstrap';
import newUserBanner from "../../../assets/images/news/newUserBanner.jpg";
import newUser from "../../../assets/images/news/newUser.png";
import ReactQuill from 'react-quill';
import i18n from "i18next";
import { languageSelection } from '../../../dynamicController';
import dayjs from 'dayjs';

const STORETYPE = process.env.REACT_APP_STORETYPE;

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

class AddNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            inbox: true,
            promotion: true,
            defaultLanguage: "en",
            newsArray: [{ language: "en", title: "", description: "", short_description: null }],
            banner: null,
            image: null,
            startDate: "",
            endDate: "",
            bannerUrl: "",
            imageUrl: "",
            loading: false,
        };
    };

    componentDidMount() {
        if (!this.props.client || !this.props.location.state || !this.props.client.news_setting) {
            this.props.history.push('/client');
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => {

        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    handleDate = (dates, dateStrings) => {
        if (dates) {
            this.setState({
                startDate: dateStrings[0],
                endDate: dateStrings[1]
            });
        } else {
            this.setState({
                startDate: null,
                endDate: null
            });
        }
    };

    removeFields = (index) => {
        let list = [...this.state.newsArray];
        list.splice(index, 1);
        this.setState({ ...this.state, newsArray: list })
    };

    addFields = () => {
        this.setState({ ...this.state, newsArray: [...this.state.newsArray, { language: "en", title: "", description: "", short_description: null }] })
    }

    handleImage = e => {

        const file = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (file) {
            if (imageRegex.test(file.type)) {
                if (jpgRegex.test(file.name) || pngRegex.test(file.name) || jpegRegex.test(file.name)) {

                    if (file.size > 2097152) {
                        mySwal.fire(i18n.t("Info"), i18n.t("The image size is over 2MB"), "info");
                        e.target.value = "";
                    } else {

                        if (e.target.id === "banner") {
                            const bannerObjectUrl = URL.createObjectURL(e.target.files[0]);
                            this.setState({
                                ...this.state,
                                bannerUrl: bannerObjectUrl,
                                banner: e.target.files[0]
                            });
                        } else if (e.target.id === "image") {
                            const imageObjectUrl = URL.createObjectURL(e.target.files[0]);
                            this.setState({
                                ...this.state,
                                imageUrl: imageObjectUrl,
                                image: e.target.files[0]
                            });
                        }

                    }

                } else {
                    mySwal.fire(i18n.t("Info"), i18n.t("Please only use png, jpg or jpeg file extension type"), "info");
                    e.target.value = "";
                }
            } else {
                mySwal.fire(i18n.t("Info"), i18n.t("Please only use image type file"), "info");
                e.target.value = "";
            }
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        const { banner, image, startDate, endDate, defaultLanguage, newsArray, inbox, promotion } = this.state;

        const defaultNews = newsArray.filter(news => news.language === defaultLanguage)[0]

        let submit = true
        let checkLanguage = []

        for (let i = 0; i < newsArray.length; i++) {
            if (!newsArray[i].title) {
                submit = false
                mySwal.fire(i18n.t("Error"), i18n.t("Please fill in the title"), "error");
            }

            if (!newsArray[i].description) {
                submit = false
                mySwal.fire(i18n.t("Error"), i18n.t("Please fill in the description"), "error");
            }

            if (STORETYPE === "cleanpro" && !newsArray[i].short_description) {
                submit = false
                mySwal.fire(i18n.t("Error"), i18n.t("Please fill in the short description"), "error");
            }

            if (!checkLanguage.includes(newsArray[i].language)) {
                checkLanguage.push(newsArray[i].language)
            } else {
                mySwal.fire(i18n.t("Error"), i18n.t("Duplicate language selected"), "error");
                submit = false
            }
        }

        if (submit) {
            if (startDate && !endDate) {
                mySwal.fire(i18n.t("Info"), i18n.t("Please select the end date for your news"), "info");
            } else if (!defaultNews) {
                mySwal.fire(i18n.t("Error"), i18n.t("Please provide the news with the default language"), "error");
            } else if (!banner) {
                mySwal.fire(i18n.t("Error"), i18n.t("Please provide the banner image"), "error");
            } else if (!image) {
                mySwal.fire(i18n.t("Error"), i18n.t("Please provide the news image"), "error");
            } else if (defaultNews && banner && image) {
                mySwal.fire({
                    title: i18n.t("Confirmation"),
                    text: i18n.t("Are you sure you want to add news?"),
                    icon: "question",
                    showCancelButton: true,
                    cancelButtonText: i18n.t("No"),
                    confirmButtonText: i18n.t("Yes"),
                    cancelButtonColor: "#d33"
                })
                    .then(click => {

                        if (click.isConfirmed && click.value) {
                            this.setState({
                                ...this.state,
                                loading: true
                            });

                            const sendThis = {
                                id: this.props.location.state.distributorId,
                                role: "distributor",
                                title: defaultNews.title,
                                description: defaultNews.description,
                                shortDescription: defaultNews.short_description,
                                language: defaultLanguage,
                                newsArray,
                                startDate,
                                endDate,
                                inbox,
                                promotion
                            };

                            axios.post('/api/admin/setting/addNews', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                                .then(res => {

                                    if (banner && image) {
                                        const formData = new FormData();
                                        formData.append("banner", banner);
                                        formData.append("image", image);
                                        formData.append("id", res.data.id);

                                        axios.post('/api/admin/setting/addNewsImage', formData, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                                            .then(res => {
                                                this.setState({
                                                    ...this.state,
                                                    inbox: true,
                                                    promotion: true,
                                                    defaultLanguage: "en",
                                                    newsArray: [{ language: "en", title: "", description: "", short_description: null }],
                                                    banner: null,
                                                    image: null,
                                                    startDate: "",
                                                    endDate: "",
                                                    bannerUrl: "",
                                                    imageUrl: "",
                                                    loading: false,
                                                });

                                                mySwal.fire(i18n.t("Success"), i18n.t("News added successfully"), "success");

                                                this.props.history.push("/client/news", { distributorId: this.props.location.state.distributorId });
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    ...this.state,
                                                    loading: false
                                                });
                                                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                                            });
                                    }

                                })
                                .catch(err => {
                                    this.setState({
                                        ...this.state,
                                        loading: false
                                    });
                                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                                });
                        }
                    })
            } else {
                mySwal.fire(i18n.t("Info"), i18n.t("Please fill in all the required fields before submit"), "info");
            }
        }
    };

    render() {

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Add News")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="news-preview-container">
                                    <label htmlFor="banner">{i18n.t("Banner Image")}: </label>
                                    <Card className="news-banner-card">
                                        <Card.Img variant="top" src={this.state.bannerUrl ? this.state.bannerUrl : newUserBanner} alt="bannerImage" />
                                        <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                            <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <label htmlFor="image">{i18n.t("News Image")}: </label>
                                    <div>
                                        <img src={this.state.imageUrl ? this.state.imageUrl : newUser} alt="newsImage" style={{ maxHeight: "300px", padding: "10px" }} className="img-fluid" />
                                    </div>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="defaultLanguage">{i18n.t("Default Language")} : </label><br />
                                        <select className="browser-default form-select" id="defaultLanguage" name="defaultLanguage" value={this.state.defaultLanguage} onChange={e => this.handleChange(e)} required>
                                            <option value="" disabled>{i18n.t("Select Default Language")}</option>
                                            {
                                                languageSelection.map((language, index) => {
                                                    return (<option value={language.value} key={index}>{language.title}</option>)
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="banner">{i18n.t("Banner")} ({i18n.t("max")}: 2MB) ({i18n.t("aspect ratio")}: 2:1): * </label><br />
                                        <input type="file" id="banner" name="banner" accept="image/*" onChange={this.handleImage} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="image">{i18n.t("Image")} ({i18n.t("max")}: 2MB) ({i18n.t("aspect ratio")}: 6:5): * </label><br />
                                        <input type="file" id="image" name="image" accept="image/*" onChange={this.handleImage} />
                                    </div>

                                    {
                                        STORETYPE === "cleanpro" ? (
                                            <div>
                                                <label>{i18n.t("Display in")}:</label>
                                                <div className="d-flex justify-content-around">

                                                    <div className="form-group">
                                                        <label>
                                                            <input type="checkbox" id="inbox" name="inbox" checked={this.state.inbox} onChange={e => this.setState({ ...this.state, inbox: !this.state.inbox })} />
                                                            <span>{i18n.t("Inbox Page")}</span>
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            <input type="checkbox" id="promotion" name="promotion" checked={this.state.promotion} onChange={e => this.setState({ ...this.state, promotion: !this.state.promotion })} />
                                                            <span>{i18n.t("Promotion Banner")}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="form-group">
                                        <label htmlFor="date">{i18n.t("Select Period: (optional)")} </label>
                                        <RangePicker
                                            onChange={this.handleDate}
                                            style={{ width: '100%' }}
                                            value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
                                        />
                                    </div>

                                    {
                                        this.state.newsArray.map((news, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className="row mt-5">
                                                        <div className="col-4 m-auto">
                                                            <div className="form-group">
                                                                <label htmlFor="language">{i18n.t("Language")} : </label>
                                                                <select className="browser-default form-select" id="language" name="language" required value={news.language} onChange={e => {
                                                                    let list = [...this.state.newsArray];
                                                                    list[i].language = e.target.value
                                                                    this.setState({ ...this.state, newsArray: list })
                                                                }}>
                                                                    <option value="" disabled>{i18n.t("Select Language")}</option>
                                                                    {
                                                                        languageSelection.map((language, index) => {
                                                                            return (<option value={language.value} key={index}>{language.title}</option>)
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="title">{i18n.t("Title")}: * </label>
                                                                <input type="text" id="title" name="title" value={news.title} required onChange={e => {
                                                                    let list = [...this.state.newsArray];
                                                                    list[i].title = e.target.value
                                                                    this.setState({ ...this.state, newsArray: list })
                                                                }} />
                                                            </div>

                                                            {
                                                                STORETYPE === "cleanpro" ? (
                                                                    <div className="form-group">
                                                                        <label htmlFor="short_description">{i18n.t("Short Description")}: * </label>
                                                                        <ReactQuill id="short_description" name="short_description" theme="snow" required value={news.short_description} onChange={e => {
                                                                            let list = [...this.state.newsArray];
                                                                            list[i].short_description = e
                                                                            this.setState({ ...this.state, newsArray: list })
                                                                        }} />
                                                                    </div>
                                                                ) : null
                                                            }

                                                            <div className="form-group">
                                                                <button type="button" className="btn btn-secondary" onClick={(e) => this.removeFields(i)}>
                                                                    {i18n.t("Remove")}
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="col form-group">
                                                            <label htmlFor="description">{i18n.t("Description")}: * </label>
                                                            <ReactQuill id="description" name="description" theme="snow" required value={news.description} onChange={e => {
                                                                let list = [...this.state.newsArray];
                                                                list[i].description = e
                                                                this.setState({ ...this.state, newsArray: list })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        languageSelection.length !== this.state.newsArray.length ? (
                                            <div className="form-group">
                                                <button type="button" className="btn btn-dark" onClick={(e) => this.addFields()}>
                                                    {i18n.t("Add Translation")}
                                                </button>
                                            </div>
                                        ) : null
                                    }

                                    <div>
                                        {
                                            this.state.loading
                                                ? <Loading />
                                                : (
                                                    <div>
                                                        <input type="submit" value={i18n.t("SUBMIT")} className="btn btn-primary form-control mb-1" />
                                                        <button onClick={() => this.props.history.push("/client/news", { distributorId: this.props.location.state.distributorId })} className="btn btn-danger mt-1 form-control font-weight-bold">{i18n.t("Cancel")}</button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(AddNews);