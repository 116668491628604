/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import PaymentDone from '../../../../assets/images/logo/PaymentDone.png';
import WasherIcon from '../../../../assets/images/logo/PaymentWasherLogo.png';
import DryerIcon from '../../../../assets/images/logo/DryerPaymentLogo.png';
import VendingIcon from "../../../../assets/images/logo/VendingLogo.png";
import HotWarmColdButton from '../../../../assets/images/logo/HotWarmColdButton.png';
import { currency } from "../../../../dynamicController";
import './Responsive.css'

import Faq from './Faq.js'

const ThankYou = ({ setPage, data, machineType, machine, user, sendThis }) => {
    const { t } = useTranslation();
    const [showFaq, setShowFaq] = useState(false);
    const [stamp, setStamp] = useState(false);
    const [stampVoucherSetting, setStampVoucherSetting] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPage("Start")
        }, 15000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (data) {
            if (sendThis.paymentAmount >= data.stamp_transaction_amount) {
                const washerVoucherSetting = data.voucherSetting.filter(setting => setting.type === "Washer Stamp")[0]
                const dryerVoucherSetting = data.voucherSetting.filter(setting => setting.type === "Dryer Stamp")[0]
                const combinedVoucherSetting = data.voucherSetting.filter(setting => setting.type === "Combined Stamp")[0]

                if (data.combined) {
                    if (combinedVoucherSetting.voucher_eligible) {
                        setStampVoucherSetting(combinedVoucherSetting)
                        setStamp(true);
                    }
                } else {
                    if (data.specific_stamp === 'both') {
                        if (machineType === "Washer") {
                            if (washerVoucherSetting.voucher_eligible) {
                                setStampVoucherSetting(washerVoucherSetting)
                                setStamp(true);
                            }
                        } else if (machineType === 'Dryer') {
                            if (dryerVoucherSetting.voucher_eligible) {
                                setStampVoucherSetting(dryerVoucherSetting)
                                setStamp(true);
                            }
                        }
                    } else if (data.specific_stamp === 'washerOnly') {
                        if (machineType === 'Washer') {
                            if (washerVoucherSetting.voucher_eligible) {
                                setStampVoucherSetting(washerVoucherSetting)
                                setStamp(true);
                            }
                        }
                    } else if (data.specific_stamp === 'dryerOnly') {
                        if (machineType === 'Dryer') {
                            if (dryerVoucherSetting.voucher_eligible) {
                                setStampVoucherSetting(dryerVoucherSetting)
                                setStamp(true);
                            }
                        }
                    } else {
                        setStamp(false);
                    }
                }
            } else {
                setStamp(false);
            }
        }
    }, [data, sendThis.paymentAmount, machineType]);

    const getMachineImage = (type) => {
        switch (type) {
            case 'Washer':
                return WasherIcon;
            case 'Dryer':
                return DryerIcon;
            case 'Vending Machine':
                return VendingIcon;
            default:
                return null;
        }
    };

    return (
        <div id="thank-you-screen" className="d-flex flex-column align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px", position: "relative" }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt='Home Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt='FAQ Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setShowFaq(!showFaq)}
                    />
                    <img
                        src={BackLogo}
                        alt='Back Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )}
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data.outlet_fullname}
                    </h2>
                )}
            </div>

            <div className="content d-flex flex-column align-items-center justify-content-center" style={{ height: '100%', textAlign: 'center' }}>
                <img
                    src={PaymentDone}
                    alt='Payment Done'
                    style={{ height: '200px', marginBottom: '20px' }}
                />

                <h2 style={{ color: '#004FC1', fontSize: '60px', fontWeight: '600', marginBottom: '20px' }}>
                    {t('Thank you for payment!')}
                </h2>

                {(machineType === 'Washer' || machineType === 'Dryer') && (
                    user ? (
                        <p style={{ color: '#000', fontSize: '40px', fontWeight: '600', lineHeight: '1.4', margin: '20px 0', whiteSpace: 'pre-line' }}>
                            {stamp && stampVoucherSetting ? (
                                <div>
                                    <p>
                                        {
                                            machineType === "Washer"
                                                ? t("You've earned 1 e-stamp for your wash!")
                                                : machineType === "Dryer"
                                                    ? t("You've earned 1 e-stamp for your dry!")
                                                    : ""
                                        }
                                    </p>
                                    <p>
                                        {
                                            data.combined
                                                ? stampVoucherSetting.voucher_type === "Flat"
                                                    ? t("Collect 10 e-stamps Wash & Dry to earn FREE {{ amount }} credits voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                    : t("Collect 10 e-stamps Wash & Dry to earn a {{ amount }}% voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                : machineType === 'Washer'
                                                    ? stampVoucherSetting.voucher_type === 'Flat'
                                                        ? t("Collect 10 e-stamps Wash to earn FREE {{ amount }} credits voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                        : t("Collect 10 e-stamps Wash to earn a {{ amount }}% voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                    : machineType === 'Dryer'
                                                        ? stampVoucherSetting.voucher_type === 'Flat'
                                                            ? t("Collect 10 e-stamps Dry to earn FREE {{ amount }} credits voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                            : t("Collect 10 e-stamps Dry to earn a {{ amount }}% voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                        : ""
                                        }
                                    </p>
                                </div>
                            ) : null}
                        </p>
                    ) : (
                        <p style={{ color: '#000', fontSize: '40px', fontWeight: '600', lineHeight: '1.4', margin: '20px 0', whiteSpace: 'pre-line' }}>
                            {stamp && stampVoucherSetting ? (
                                <div>
                                    <p>
                                        {
                                            machineType === "Washer"
                                                ? t("You've missed 1 e-stamp for your wash!")
                                                : machineType === "Dryer"
                                                    ? t("You've missed 1 e-stamp for your dry!")
                                                    : ""
                                        }
                                    </p>
                                    <p>
                                        {
                                            data.combined
                                                ? stampVoucherSetting.voucher_type === "Flat"
                                                    ? t("Sign Up & Collect 10 e-stamps Wash & Dry to earn FREE {{ amount }} credits voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                    : t("Sign Up & Collect 10 e-stamps Wash & Dry to earn a {{ amount }}% voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                : machineType === 'Washer'
                                                    ? stampVoucherSetting.voucher_type === 'Flat'
                                                        ? t("Sign Up & Collect 10 e-stamps Wash to earn FREE {{ amount }} credits voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                        : t("Sign Up & Collect 10 e-stamps Wash to earn a {{ amount }}% voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                    : machineType === 'Dryer'
                                                        ? stampVoucherSetting.voucher_type === 'Flat'
                                                            ? t("Sign Up & Collect 10 e-stamps Dry to earn FREE {{ amount }} credits voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                            : t("Sign Up & Collect 10 e-stamps Dry to earn a {{ amount }}% voucher.", { amount: stampVoucherSetting.voucher_amount })
                                                        : ""
                                        }
                                    </p>
                                </div>
                            ) : null}
                        </p>
                    )
                )}

                {machineType === 'Vending Machine' ? (
                    <p style={{ color: '#000', fontSize: '40px', fontWeight: '600', lineHeight: '1.4', margin: '20px 0' }}>
                        {t("Please select your items at the vending machine")}
                    </p>
                ) : machineType === "Washer" || (machineType === "Dryer" && !machine?.running) ? (
                    <p style={{ color: '#000', fontSize: '40px', fontWeight: '600', lineHeight: '1.4', margin: '20px 0' }}>
                        {t("Please choose the temperature on the machine and press")} <br /> <span style={{ color: "red", fontSize: "90px" }}>{t("START")}</span>
                    </p>
                ) : null}

                <p style={{ color: '#000', fontSize: '40px', fontWeight: 'bold', lineHeight: '1.4', margin: '20px 0' }}>
                    {t('Your machine is')} {machine.name.split('-')[1]}
                </p>

                <div className="position-relative" style={{ width: '300px', height: '300px', marginBottom: '100px' }}>
                    <div style={{ backgroundColor: '#004FC1', width: '100%', height: '100%', borderRadius: '10px', padding: '20px', position: 'relative' }}>
                        <img
                            src={getMachineImage(machineType)}
                            alt={machineType}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                        {machineType !== 'Vending Machine' && (
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '55%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '64px',
                                    fontWeight: 'bold',
                                    color: '#000',
                                    pointerEvents: 'none'
                                }}
                            >
                                {machine.name.split('-')[1]}
                            </span>
                        )}
                    </div>

                    {machineType !== 'Vending Machine' && (
                        <div
                            style={{
                                backgroundColor: '#004FC1',
                                color: '#fff',
                                textAlign: 'center',
                                fontSize: '34px',
                                fontWeight: '600',
                                padding: '10px',
                                borderRadius: '0 0 10px 10px',
                                marginTop: '-10px',
                            }}
                        >
                            {t(machine.type)} {machine.capacity}
                        </div>
                    )}

                    {machineType === 'Vending Machine' && (
                        <p style={{
                            textAlign: 'center',
                            fontSize: '40px',
                            fontWeight: 'bold',
                            color: '#000',
                            marginTop: '20px',
                        }}>
                            {t('Please Collect your item from the vending machine')} {machine.name.split('-')[1]}
                        </p>
                    )}
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '30px'
                }}>
                    {(machineType === 'Washer' || machineType === 'Dryer') && (
                        <div className="position-relative" style={{ width: '500px', height: '180px', marginBottom: '30px' }}>
                            <img
                                src={HotWarmColdButton}
                                alt={sendThis.mode}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                    borderRadius: '90px',
                                }}
                            />
                            {machineType === 'Washer' && (
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '25%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '55px',
                                        fontWeight: '600',
                                        color: '#004FC1',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {t(sendThis.mode)}
                                </span>
                            )}

                            {(machineType === 'Washer' || machineType === 'Dryer') && (
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: machineType === 'Washer' ? '35%' : '25%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        color: '#004FC1',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {sendThis.duration} {t("minutes")}
                                </span>
                            )}

                            {(machineType === 'Washer' || machineType === 'Dryer') && (
                                <span
                                    style={{
                                        position: 'absolute',
                                        bottom: '0%',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        fontSize: '55px',
                                        fontWeight: '600',
                                        color: '#004FC1',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {currency(machine.country)} {sendThis.paymentAmount}
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
    };
};

export default connect(mapStateToProps, null)(ThankYou);
