import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import LaundryPassUsageReportCard from "../../admin/components/report/LaundryPassUsageReportCard";

const LaundryPassUsageReport = ({ token, distributor }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        if (!distributor || !distributor.laundry_pass_usage_report) {
            history.push('/distributor')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />
            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <LaundryPassUsageReportCard 
                        role="distributor"
                        token={token}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(LaundryPassUsageReport);