import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

// import logo1 from '../../../assets/images/logo/logo1.png';
// import laundroLogo1 from '../../../assets/images/logo/laundroLogo1.png';
import { logo1Controller } from "../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, admin }) => {

	const { t } = useTranslation();

	const handleClick = (feature) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': feature,
			'user_id': admin ? admin.username : null,
			'email': admin ? admin.email : null,
			'brand': process.env.REACT_APP_STORETYPE,
			'role': 'operator'
		})
	}

	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/admin/dashboard">
					<img src={logo1Controller(themeLogo)} alt="logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-house-door-fill"></i>}>
						{t("Dashboard")} <Link to="/admin/dashboard" onClick={() => handleClick("Dashboard")} />
					</MenuItem>

					{
						admin && (admin.store_performance || admin.user_performance || admin.voucher_redemption || admin.token_report || admin.sms_report || admin.discount_code_report || admin.voucher_code_report || admin.floating_token_report || admin.epayment_report || admin.floating_point_report || admin.referral_reward_history_report || admin.user_usage_report) ? (
							<SubMenu title={t("Report")} icon={<i className="bi bi-file-bar-graph-fill"></i>}>
								{
									admin.store_performance ? (
										<MenuItem>
											{t("Store Performance")} <Link to="/admin/outlet/performance" onClick={() => handleClick("Store Performance")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.user_performance ? (
										<MenuItem>
											{t("User Performance")} <Link to="/admin/user/performance" onClick={() => handleClick("User Performance")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.voucher_redemption ? (
										<MenuItem>
											{t("Voucher Redemption")} <Link to="/admin/voucher/redemption" onClick={() => handleClick("Voucher Redemption")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.token_report ? (
										<MenuItem>
											{t("Token Report")} <Link to="/admin/token/report" onClick={() => handleClick("Token Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.floating_token_report ? (
										<MenuItem>
											{t("Floating Token Report")} <Link to="/admin/floatingToken/report" onClick={() => handleClick("Floating Token Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.floating_point_report ? (
										<MenuItem>
											{t("Floating Point Report")} <Link to="/admin/floatingPoint/report" onClick={() => handleClick("Floating Point Report")} />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE !== "cuci" && admin.sms_report ? (
										<MenuItem>
											{t("SMS Report")} <Link to="/admin/sms/report" onClick={() => handleClick("SMS Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.discount_code_report ? (
										<MenuItem>
											{t("Discount Code Report")} <Link to="/admin/discountCode/report" onClick={() => handleClick("Discount Code Report")} />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE === "cleanpro" && admin.voucher_code_report ? (
										<MenuItem>
											{t("Voucher Code Report")} <Link to="/admin/voucherCode/report" onClick={() => handleClick("Voucher Code Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.epayment_report ? (
										<MenuItem>
											{t("E-Payment Report")} <Link to="/admin/epayment/report" onClick={() => handleClick("E-Payment Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.referral_reward_history_report ? (
										<MenuItem>
											{t("Referral Reward History Report")} <Link to="/admin/referralReward/report" onClick={() => handleClick("Referral Reward History Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.user_usage_report ? (
										<MenuItem>
											{t("User Usage Report")} <Link to="/admin/userUsage/report" onClick={() => handleClick("User Usage Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.laundry_pass_purchase_report ? (
										<MenuItem>
											{t("Laundry Pass Purchase Report")} <Link to="/admin/subscription/report" onClick={() => handleClick("Laundry Pass Purchase Report")} />
										</MenuItem>
									) : <></>
								}
								{
									admin.laundry_pass_usage_report ? (
										<MenuItem>
											{t("Laundry Pass Usage Report")} <Link to="/admin/subscription/usage/report" onClick={() => handleClick("Laundry Pass Usage Report")} />
										</MenuItem>
									) : <></>
								}
							</SubMenu>
						) : <></>
					}

					{
						admin && admin.user_detail ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-person-lines-fill"></i>}>
								{t("User Details")} <Link to="/admin/user/details" onClick={() => handleClick("User Details")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.user_reward ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gift-fill"></i>}>
								{t("User Compensation")} <Link to="/admin/giveaway" onClick={() => handleClick("User Compensation")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.voucher_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gear-fill"></i>}>
								{t("Voucher Settings")} <Link to="/admin/voucher/setting" onClick={() => handleClick("Voucher Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.token_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-coin"></i>}>
								{t("Token Settings")} <Link to="/admin/token/setting" onClick={() => handleClick("Token Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.referral_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-people-fill"></i>}>
								{t("Referral Settings")} <Link to="/admin/referral" onClick={() => handleClick("Referral Settings")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.discount_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-percent"></i>}>
								{t("Discount Code")} <Link to="/admin/discountCode" onClick={() => handleClick("Discount Code")} />
							</MenuItem>
						) : <></>
					}

					{
						process.env.REACT_APP_STORETYPE === "cleanpro" && admin && admin.voucher_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-upc"></i>}>
								{t("Voucher Code")} <Link to="/admin/voucherCode" onClick={() => handleClick("Voucher Code")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.laundry_pass ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-ticket-perforated-fill"></i>}>
								{t("Laundry Pass")} <Link to="/admin/subscription" onClick={() => handleClick("Laundry Pass")} />
							</MenuItem>
						) : <></>
					}

					{
						process.env.REACT_APP_STORETYPE !== "cuci" && process.env.REACT_APP_STORETYPE !== "cleanpro" && admin && admin.customize_color ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-palette-fill"></i>}>
								{t("Customize Color")} <Link to="/admin/setting/customize" onClick={() => handleClick("Customize Color")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.faq_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-question-circle-fill"></i>}>
								{t("FAQs")} <Link to="/admin/faq" onClick={() => handleClick("FAQs")} />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.news_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-newspaper"></i>}>
								{t("News")} <Link to="/admin/news" onClick={() => handleClick("News")} />
							</MenuItem>
						) : <></>
					}

					{/* <SubMenu defaultOpen={false} title="Outlets" icon={<i className="fas fa-cogs"></i>}>
                        {renderOutlets()}
                    </SubMenu> */}
				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		admin: state.admin.admin
	};
};

export default connect(mapStateToProps, null)(Sidebar);
