import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { currency } from "../../../../dynamicController";

import washer from "../../../../assets/images/machines/paymentWasher.png";
import dryer from "../../../../assets/images/machines/paymentDryer.png";

const colors = ["#bfd0e6", "#f3a68c", "#a0cf99", "#f5dc7b", "#eba0c4"];
let indexCount = 0;

const LaundroTransaction = ({ user, setToggled, selected, setSelected, renderDate, transactions, reloads, transfers, points, subscriptionHistory }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div>
            <div id="user-header">
                <h5>
                    <i onClick={e => setToggled(true)} className="bi bi-list me-3"></i>
                    <strong>
                        {
                            selected === "Transaction"
                                ? t("Transaction History")
                                : selected === "Reload"
                                    ? t("Reload History")
                                    : selected === "Transfer"
                                        ? t("Transfer History")
                                        : selected === "Point"
                                            ? <Trans i18nKey="{{ point }} History">{{ point: user.loyalty_point_name }} History</Trans>
                                            : selected === "Subscription"
                                                ? t("Laundry Pass Purchase History")
                                                : ""
                        }
                    </strong>
                </h5>
            </div>

            <div id="user-body">
                <p className="text-center pt-2">
                    {
                        selected === "Transaction"
                            ? t("Only up to last 3 months transaction history")
                            : selected === "Reload"
                                ? t("Only up to last 3 months reload history")
                                : selected === "Transfer"
                                    ? t("Only up to last 3 months transfer history")
                                    : selected === "Point"
                                        ? <Trans i18nKey="Only up to last 3 months {{ point }} history">Only up to last 3 months {{ point: user.loyalty_point_name }} history</Trans>
                                        : selected === "Subscription"
                                            ? t("Only up to last 3 months laundry pass history")
                                            : ""
                    }
                </p>

                {
                    user.loyalty_point || user.tokenSetting || user.subscriptionHistory.length > 0 ? (
                        <ul className="nav nav-tabs m-auto">
                            <li className="nav-item">
                                <button id="transaction-page-transaction" onClick={() => setSelected("Transaction")} className={selected === "Transaction" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Transaction" ? null : "1px solid #dee2e6" }}>
                                    {t("Transaction")}
                                </button>
                            </li>

                            {
                                user.tokenSetting && user.reload ? (
                                    <li className="nav-item">
                                        <button id="transaction-page-reload" onClick={() => setSelected("Reload")} className={selected === "Reload" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Reload" ? null : "1px solid #dee2e6" }}>
                                            {t("Reload")}
                                        </button>
                                    </li>
                                ) : null
                            }

                            {
                                user.tokenSetting && user.transfer ? (
                                    <li className="nav-item">
                                        <button id="transaction-page-transfer" onClick={() => setSelected("Transfer")} className={selected === "Transfer" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Transfer" ? null : "1px solid #dee2e6" }}>
                                            {t("Transfer")}
                                        </button>
                                    </li>
                                ) : null
                            }

                            {
                                user.loyalty_point && points.length > 0 ? (
                                    <li className="nav-item">
                                        <button id="transaction-page-point" onClick={() => setSelected("Point")} className={selected === "Point" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Point" ? null : "1px solid #dee2e6" }}>
                                            {user.loyalty_point_name}
                                        </button>
                                    </li>
                                ) : null
                            }

                            {
                                user.subscriptionHistory.length > 0 ? (
                                    <li className="nav-item">
                                        <button id="transaction-page-point" onClick={() => setSelected("Subscription")} className={selected === "Subscription" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Subscription" ? null : "1px solid #dee2e6" }}>
                                            {t("Laundry Pass")}
                                        </button>
                                    </li>
                                ) : null
                            }
                        </ul>
                    ) : null
                }

                {
                    selected === "Transaction" ? (
                        transactions.map(transaction => {
                            if (indexCount >= colors.length) {
                                indexCount = 0;
                            }
                            const color = colors[indexCount];
                            indexCount += 1;

                            return (
                                <div key={transaction.id} className="transaction-list" onClick={() => history.push("/user/trans/details", { transaction })}>
                                    <div className="img-container" style={{ backgroundColor: color }}>
                                        <img src={transaction.machine_type === "Washer" ? washer : dryer} alt="machine" className="img-fluid" />
                                    </div>

                                    <div className="info-container">
                                        <div>
                                            {
                                                transaction.transaction_type === "Package" ? (
                                                    <div>
                                                        <h5 className="font-weight-bold m-0 p-0">{t("Drop n Go Service")}</h5>
                                                        <p style={{ color: "grey", fontWeight: "bold" }}>{transaction.outlet.outlet_name}</p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <h5 className="font-weight-bold m-0 p-0">{transaction.machine_name}</h5>
                                                        <p style={{ color: "grey", fontWeight: "bold" }}>{transaction.machine_capacity}</p>
                                                    </div>
                                                )
                                            }
                                            <h6 className="font-weight-bold m-0 p-0">{renderDate(transaction.createdAt)}</h6>
                                        </div>

                                        <div className="text-right">
                                            <h5 style={{ color: transaction.status === "Success" ? "green" : transaction.status === "Failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{t(transaction.status)}</h5>
                                            <h5 style={{ color: "#ffd109", fontWeight: "bold" }}>
                                                {transaction.method === "TOKEN" ? "" : `${currency(user.country)} `}
                                                {transaction.payment_amount + transaction.sms_amount}
                                                {transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}
                                            </h5>
                                            {/* {transaction.voucherId ? (
                                                transaction.voucher.type === "Flat" ? (
                                                    <h5 className="font-weight-bold">
                                                        - {transaction.method === "TOKEN" ? "" : `${currency(user.country)} `}
                                                        {transaction.voucher.amount}
                                                        <small style={{ fontWeight: "" }}>{transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}</small>
                                                    </h5>
                                                ) : (
                                                    <h5 className="font-weight-bold">- {transaction.voucher.amount} %</h5>
                                                )
                                            ) : transaction.amount !== transaction.payment_amount ? (
                                                <h5 className="font-weight-bold">
                                                    - {transaction.method === "TOKEN" ? "" : `${currency(user.country)} `}
                                                    {(transaction.amount - transaction.payment_amount).toFixed(2)}
                                                    <small style={{ fontWeight: "bold", fontSize: "10px" }}>{transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}</small>
                                                </h5>
                                            ) : null} */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : selected === "Reload" ? (
                        reloads.map(reload => {
                            if (indexCount >= colors.length) {
                                indexCount = 0;
                            }
                            const color = colors[indexCount];
                            indexCount += 1;

                            return (
                                <div key={reload.id} className="transaction-list" onClick={() => history.push("/user/trans/details", { reload })}>
                                    <div className="img-container" style={{ backgroundColor: color }}>
                                        <i className="fas bi-coin" style={{ fontSize: "30px", marginTop: "15px" }}></i>
                                    </div>

                                    <div className="info-container">
                                        <div>
                                            <h5 className="font-weight-bold">{currency(user.country)} {reload.amount}</h5>
                                            <h6 className="font-weight-bold">{renderDate(reload.createdAt)}</h6>
                                        </div>

                                        <div className="text-right">
                                            <h5 style={{ color: reload.status === "Success" ? "green" : reload.status === "Failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{t(reload.status)}</h5>
                                            <h5 style={{ color: "#ffd109", fontWeight: "bold" }}>
                                                {reload.token} {t("E-TOKEN")}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : selected === "Transfer" ? (
                        transfers.map(transfer => {
                            if (indexCount >= colors.length) {
                                indexCount = 0;
                            }
                            const color = colors[indexCount];
                            indexCount += 1;

                            return (
                                <div key={transfer.id} className="transaction-list" onClick={() => history.push("/user/trans/details", { transfer })}>
                                    <div className="img-container" style={{ backgroundColor: color }}>
                                        <i className="fas bi-arrow-left-right" style={{ fontSize: "30px", marginTop: "15px" }}></i>
                                    </div>

                                    <div className="info-container">
                                        <div>
                                            {
                                                transfer.amount > 0 ? (
                                                    <div>
                                                        <h5 className="font-weight-bold m-0 p-0">{transfer.transfer_from.number}</h5>
                                                        <p style={{ color: "grey", fontWeight: "bold" }}>{transfer.transfer_from.name}</p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <h5 className="font-weight-bold m-0 p-0">{transfer.transfer_to.number}</h5>
                                                        <p style={{ color: "grey", fontWeight: "bold" }}>{transfer.transfer_to.name}</p>
                                                    </div>
                                                )
                                            }
                                            <h6 className="font-weight-bold">{renderDate(transfer.createdAt)}</h6>
                                        </div>

                                        <div className="text-right">
                                            <h5 style={{ color: transfer.amount > 0 ? "green" : "red", fontWeight: "bold" }}>
                                                {transfer.amount} {t("E-TOKEN")}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : selected === "Point" ? (
                        points.map(point => {
                            if (indexCount >= colors.length) {
                                indexCount = 0;
                            }
                            const color = colors[indexCount];
                            indexCount += 1;

                            return (
                                <div key={point.id} className="transaction-list" onClick={() => history.push("/user/trans/details", { point })}>
                                    <div className="img-container" style={{ backgroundColor: color }}>
                                        <i className="fas bi-currency-exchange" style={{ fontSize: "30px", marginTop: "15px" }}></i>
                                    </div>

                                    <div className="info-container">
                                        <div>
                                            <h5 className="font-weight-bold">{t(point.type)}</h5>
                                            <h6 className="font-weight-bold">{renderDate(point.createdAt)}</h6>
                                        </div>

                                        <div className="text-right">
                                            <h5 style={{ color: point.amount > 0 ? "green" : "red", fontWeight: "bold" }}>
                                                {point.amount} {user.loyalty_point_name}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : selected === "Subscription" ? (
                        subscriptionHistory.map(subscription => {
                            if (indexCount >= colors.length) {
                                indexCount = 0;
                            }
                            const color = colors[indexCount];
                            indexCount += 1;

                            return (
                                <div key={subscription.id} className="transaction-list" onClick={() => history.push("/user/trans/details", { subscription })}>
                                    <div className="img-container" style={{ backgroundColor: color }}>
                                        <i className="fas bi-ticket-perforated-fill" style={{ fontSize: "30px", marginTop: "15px" }}></i>
                                    </div>

                                    <div className="info-container">
                                        <div>
                                            <h5 className="font-weight-bold">{subscription.name}</h5>
                                            <h6 className="font-weight-bold">{renderDate(subscription.createdAt)}</h6>
                                        </div>

                                        <div className="text-right">
                                            <h5 style={{ color: subscription.status === "Success" ? "green" : subscription.status === "Failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{t(subscription.status)}</h5>
                                            <h5 className="font-weight-bold">{currency(user.country)} {subscription.price}</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : null
                }

            </div>
        </div>
    )
}

export default LaundroTransaction;