import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { DatePicker, Select, Table, Input } from "antd";
import { dateFormat, timeFormat } from "../../../../dynamicController";
import Loading from "../Loading";

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

const FloatingTokenReportCard = ({ role, token, operators, distributors }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [summaryData, setSummaryData] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [operatorId, setOperatorId] = useState(null)
    const [distributorId, setDistributorId] = useState(null)
    const [operatorOption, setOperatorOpion] = useState([])
    const [distributorOption, setDistributorOption] = useState([])
    const [searchText, setSearchText] = useState("")
    const [allData, setAllData] = useState([])

    useEffect(() => {
        if (role === "client") {
            if (operators) {
                let operatorList = []
                for (let i = 0; i < operators.length; i++) {
                    let operatorObject = {
                        value: operators[i].id,
                        label: operators[i].username
                    }
                    operatorList.push(operatorObject)
                }
                setOperatorOpion(operatorList)
            }
            if (distributors) {
                let distributorList = []
                for (let i = 0; i < distributors.length; i++) {
                    let distributorObject = {
                        value: distributors[i].id,
                        label: distributors[i].name
                    }
                    distributorList.push(distributorObject)
                }
                setDistributorOption(distributorList)
            }
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/userFloatingTokenReport", { startDate, endDate }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setAllData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });

            axios
                .post("/api/admin/report/floatingTokenReport", { startDate, endDate }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setSummaryData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDate = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        } else {
            setStartDate("")
            setEndDate("")
        }
    };

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setOperatorId(null)
        setDistributorId(null)
        setData([])
        setAllData([])
        setSummaryData([])
        setSearchText("")
        if (role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/userFloatingTokenReport", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setAllData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });

            axios
                .post("/api/admin/report/floatingTokenReport", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setSummaryData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleFilter = () => {
        if (role === "client" && !distributorId && !operatorId) {
            mySwal.fire(t("Error"), t("Please select distributor or operator"), "error")
        } else {
            setLoading(true)
            setSearchText("")
            axios
                .post("/api/admin/report/userFloatingTokenReport", { startDate, endDate, operatorId, distributorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setAllData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });

            axios
                .post("/api/admin/report/floatingTokenReport", { startDate, endDate, operatorId, distributorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setSummaryData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleChangeDistributor = (value) => {
        setDistributorId(value)
        setOperatorId(null)
    }

    const handleChangeOperator = (value) => {
        setOperatorId(value)
        setDistributorId(null)
    }

    const filterByValue = (data, searchValue) => {
        const lowercasedValue = searchValue.toLowerCase();
        return data.filter(item => {
            return Object.values(item).some(val =>
                val && val.toString().toLowerCase().includes(lowercasedValue)
            );
        });
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
        if (!e.target.value) {
            setData(allData)
        } else {
            const filteredData = filterByValue(allData, e.target.value)
            setData(filteredData)
        }
    }

    const tableColumns = [
        {
            title: t("User Name"),
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            className: "userdetails-column",
            onHeaderCell: () => ({ className: "userdetails-header" }),
        },
        {
            title: t("Member ID"),
            dataIndex: "member_id",
            key: "member_id",
            sorter: (a, b) => a.member_id.localeCompare(b.member_id),
            className: "userdetails-column",
            onHeaderCell: () => ({ className: "userdetails-header" }),
        },
        {
            title: t("Phone Number"),
            dataIndex: "number",
            key: "number",
            sorter: (a, b) => a.number.localeCompare(b.number),
            className: "userdetails-column",
            onHeaderCell: () => ({ className: "userdetails-header" }),
        },
        {
            title: t("Email Address"),
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            className: "userdetails-column",
            onHeaderCell: () => ({ className: "userdetails-header" }),
        },
        {
            title: t("Balance"),
            dataIndex: "balance",
            key: "balance",
            sorter: (a, b) => parseFloat(a.balance) - parseFloat(b.balance),
            className: "userdetails-column",
            onHeaderCell: () => ({ className: "userdetails-header" }),
        },
        {
            title: t("Giveaway"),
            dataIndex: "giveaway",
            key: "giveaway",
            sorter: (a, b) => parseFloat(a.giveaway) - parseFloat(b.giveaway),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Purchase"),
            dataIndex: "purchase",
            key: "purchase",
            sorter: (a, b) => parseFloat(a.purchase) - parseFloat(b.purchase),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Referral"),
            dataIndex: "referral",
            key: "referral",
            sorter: (a, b) => parseFloat(a.referral) - parseFloat(b.referral),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Transfer In"),
            dataIndex: "transfer_in",
            key: "transfer_in",
            sorter: (a, b) => parseFloat(a.transfer_in) - parseFloat(b.transfer_in),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Refund"),
            dataIndex: "refund",
            key: "refund",
            sorter: (a, b) => parseFloat(a.refund) - parseFloat(b.refund),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Migrate"),
            dataIndex: "migrate",
            key: "migrate",
            sorter: (a, b) => parseFloat(a.migrate) - parseFloat(b.migrate),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: `${t("Update")} (+)`,
            dataIndex: "update_positive",
            key: "update_positive",
            sorter: (a, b) => parseFloat(a.update_positive) - parseFloat(b.update_positive),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Transaction"),
            dataIndex: "transaction",
            key: "transaction",
            sorter: (a, b) => parseFloat(a.transaction) - parseFloat(b.transaction),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Transfer Out"),
            dataIndex: "transfer_out",
            key: "transfer_out",
            sorter: (a, b) => parseFloat(a.transfer_out) - parseFloat(b.transfer_out),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: `${t("Update")} (-)`,
            dataIndex: "update_negative",
            key: "update_negative",
            sorter: (a, b) => parseFloat(a.update_negative) - parseFloat(b.update_negative),
            className: "tokentypes-column",
            onHeaderCell: () => ({ className: "tokentypes-header" }),
        },
        {
            title: t("Total In"),
            dataIndex: "reload",
            key: "reload",
            sorter: (a, b) => parseFloat(a.reload) - parseFloat(b.reload),
            className: "summary-column",
            onHeaderCell: () => ({ className: "summary-header" }),
        },
        {
            title: t("Total Out"),
            dataIndex: "usage",
            key: "usage",
            sorter: (a, b) => parseFloat(a.usage) - parseFloat(b.usage),
            className: "summary-column",
            onHeaderCell: () => ({ className: "summary-header" }),
        },
        {
            title: t("Remaining"),
            dataIndex: "remaining",
            key: "remaining",
            sorter: (a, b) => parseFloat(a.remaining) - parseFloat(b.remaining),
            className: "summary-column",
            onHeaderCell: () => ({ className: "summary-header" }),
        },
    ];

    let dataCsv = [];

    if (data) {
        for (let i = 0; i < data.length; i++) {
            let dataObject = {
                balance: data[i].balance,
                reload: data[i].reload,
                usage: data[i].usage,
                giveaway: data[i].giveaway,
                purchase: data[i].purchase,
                referral: data[i].referral,
                transfer_in: data[i].transfer_in,
                refund: data[i].refund,
                migrate: data[i].migrate,
                update_positive: data[i].update_positive,
                transaction: data[i].transaction,
                transfer_out: data[i].transfer_out,
                update_negative: data[i].update_negative,
                remaining: data[i].remaining,
                member_id: data[i].member_id,
                name: data[i].name,
                number: `=""${data[i].number}""`,
                email: data[i].email,
            }
            dataCsv.push(dataObject);
        }
    }

    const csvData = {
        headers: [
            { label: "User Name", key: "name" },
            { label: "Member ID", key: "member_id" },
            { label: "Phone Number", key: "number" },
            { label: "Email Address", key: "email" },
            { label: "Balance", key: "balance" },
            { label: "Giveaway", key: "giveaway" },
            { label: "Purchase", key: "purchase" },
            { label: "Referral", key: "referral" },
            { label: "Transfer In", key: "transfer_in" },
            { label: "Refund", key: "refund" },
            { label: "Migrate", key: "migrate" },
            { label: "Update (+)", key: "update_positive" },
            { label: "Transaction", key: "transaction" },
            { label: "Transfer Out", key: "transfer_out" },
            { label: "Update (-)", key: "update_negative" },
            { label: "Total In", key: "reload" },
            { label: "Total Out", key: "usage" },
            { label: "Remaining", key: "remaining" },
        ],
        data: dataCsv
    };

    const summaryTableColumns = [
        {
            title: t("Balance"),
            dataIndex: "balance",
            key: "balance",
        },
        {
            title: t("Giveaway"),
            dataIndex: "giveaway",
            key: "giveaway",
        },
        {
            title: t("Purchase"),
            dataIndex: "purchase",
            key: "purchase",
        },
        {
            title: t("Referral"),
            dataIndex: "referral",
            key: "referral",
        },
        {
            title: t("Transfer In"),
            dataIndex: "transfer_in",
            key: "transfer_in",
        },
        {
            title: t("Refund"),
            dataIndex: "refund",
            key: "refund",
        },
        {
            title: t("Migrate"),
            dataIndex: "migrate",
            key: "migrate",
        },
        {
            title: `${t("Update")} (+)`,
            dataIndex: "update_positive",
            key: "update_positive",
        },
        {
            title: t("Transaction"),
            dataIndex: "transaction",
            key: "transaction",
        },
        {
            title: t("Transfer Out"),
            dataIndex: "transfer_out",
            key: "transfer_out",
        },
        {
            title: `${t("Update")} (-)`,
            dataIndex: "update_negative",
            key: "update_negative",
        },
        {
            title: t("Total In"),
            dataIndex: "reload",
            key: "reload",
        },
        {
            title: t("Total Out"),
            dataIndex: "usage",
            key: "usage",
        },
        {
            title: t("Remaining"),
            dataIndex: "remaining",
            key: "remaining",
        },
    ];

    const summaryCsvData = {
        headers: [
            { label: "Balance", key: "balance" },
            { label: "Giveaway", key: "giveaway" },
            { label: "Purchase", key: "purchase" },
            { label: "Referral", key: "referral" },
            { label: "Transfer In", key: "transfer_in" },
            { label: "Refund", key: "refund" },
            { label: "Migrate", key: "migrate" },
            { label: "Update (+)", key: "update_positive" },
            { label: "Transaction", key: "transaction" },
            { label: "Transfer Out", key: "transfer_out" },
            { label: "Update (-)", key: "update_negative" },
            { label: "Total In", key: "reload" },
            { label: "Total Out", key: "usage" },
            { label: "Remaining", key: "remaining" },
        ],
        data: summaryData
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("Floating Token Report")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div>
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <Select
                                    id="distributorId"
                                    name="distributorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Distributor")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeDistributor(value)}
                                    options={distributorOption}
                                    value={distributorId}
                                />
                            </div>
                        ) : null
                    }

                    {
                        role === "client" ? (
                            <div>
                                <label htmlFor="operatorId">{t("Select Operator")}: </label>
                                <Select
                                    id="operatorId"
                                    name="operatorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Operator")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeOperator(value)}
                                    options={operatorOption}
                                    value={operatorId}
                                />
                            </div>
                        ) : null
                    }

                    <div className="pb-3">
                        <label htmlFor="date">{t("Select Date")}: </label>
                        <RangePicker
                            id="date"
                            name="date"
                            placeholder={[t("Start date"), t("End date")]}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                            value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                            presets={[
                                { label: t("Today"), value: [dayjs(), dayjs()] },
                                { label: t("Yesterday"), value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                                { label: t("Last 7 Days"), value: [dayjs().subtract(6, 'day'), dayjs()] },
                                { label: t("Last 30 Days"), value: [dayjs().subtract(29, 'day'), dayjs()] },
                                { label: t("This Month"), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                { label: t("Last Month"), value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
                            ]}
                        />
                    </div>

                    <div className="d-flex">
                        <button onClick={() => handleFilter()} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={() => handleReset()} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <Table
                                    columns={summaryTableColumns}
                                    dataSource={summaryData}
                                    scroll={{ x: "max-content" }}
                                    pagination={false}
                                    style={{ marginBottom: '20px' }}
                                />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={summaryCsvData.data} headers={summaryCsvData.headers} filename={`floating_token_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <div style={{ marginBottom: "1rem", textAlign: "left" }}>
                                    <Input
                                        placeholder={t("Search")}
                                        value={searchText}
                                        onChange={handleSearchChange}
                                        allowClear
                                        style={{ width: 200, border: "none", boxShadow: "none" }}
                                    />
                                </div>
                                <Table
                                    columns={tableColumns}
                                    dataSource={data}
                                    scroll={{ x: "max-content" }}
                                    bordered
                                    pagination={{
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '50', '100'],
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} entries`
                                    }}
                                />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_floating_token_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default FloatingTokenReportCard;