import { carelineNumber, laundroText } from "../../../dynamicController";
import { connect } from "react-redux";

const { REACT_APP_STORETYPE } = process.env

const PrivacyPolicy = ({ history, operatorId, country }) => {

    return (
        <div id="agreement-page">
            <nav id="agreement-nav">
                <button onClick={e => history.goBack()} style={{ outline: "none", backgroundColor: "transparent", border: "none" }}><i className="bi bi-chevron-left"></i></button>
            </nav>

            {
                REACT_APP_STORETYPE === "cleanpro" ? (
                    <div className="mt-4 mb-5 px-5">
                        <h3 style={{ color: "#1C75BC" }}>PRIVACY POLICY</h3>
                        <div style={{ textAlign: "justify" }}>
                            <ol>
                                <b><li>INTRODUCTION</li></b>
                                <p>1.1. Welcome to Cleanpro Plus ("Platform") run by Cleanpro Laundry Holdings Sdn Bhd. and its affiliates and subsidiaries (individually and collectively, "Cleanpro Plus", "we", "us" or "our"). Cleanpro Plus takes its responsibilities under applicable privacy laws and regulations ("Privacy Laws") seriously and is committed to respecting the privacy rights and concerns of all users of our platform (we refer to the Platform and the services we provide as described in our Platform collectively as the "Services"). We recognize the importance of the personal data you have entrusted to us and believe that it is our responsibility to properly manage, protect and process your personal data. This Privacy Policy (“Privacy Policy” or “Policy”) is designed to assist you in understanding how we collect, use, disclose and/or process the personal data you have provided to us and/or we possess about you, whether now or in the future, as well as to assist you in making an informed decision before providing us with any of your personal data. Please read this Privacy Policy carefully. If you have any questions regarding this information or our privacy practices, please see the section entitled "Contact Us" at the end of this Privacy Policy.</p>
                                <p>1.2. "Personal Data" or "personal data" means data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organization has or is likely to have access. Common examples of personal data could include name, identification number and contact information.</p>
                                <p>1.3. By using the Services, registering for an account with us, visiting our Platform, or accessing the Services, you acknowledge and agree that you accept the practices, requirements, and/or policies outlined in this Privacy Policy, and you hereby consent to us collecting, using, disclosing and/or processing your personal data as described herein. If you do not consent to the processing of your personal data as described in this privacy policy, please do not use our services or access our platform. If we change our Privacy Policy, we will post those changes or the amended Privacy Policy on our Platform. We reserve the right to amend this Privacy Policy at any time.</p>
                                <b><li>WHEN WILL CLEANPRO PLUS COLLECT PERSONAL DATA?</li></b>
                                <p>2.1. We will/may collect personal data about you:</p>
                                <ol type="a">
                                    <li>when you register and/or use our Services or Platform, or open an account with us;</li>
                                    <li>when you submit any form, including, but not limited to, application forms or other forms relating to any of our products and services;</li>
                                    <li>when you enter into any agreement or provide other documentation or information in respect of your interactions with us, or when you use our products and services;</li>
                                    <li>when you interact with us, such as via telephone calls (which may be recorded), letters, fax, face-to-face meetings, social media platforms and emails;</li>
                                    <li>when you use our electronic services, or interact with us via our application or use services on our Platform. This includes, without limitation, through cookies which we may deploy when you interact with our application or Platform;</li>
                                    <li>when you carry out transactions through our Services;</li>
                                    <li>when you provide us with feedback or complaints;</li>
                                    <li>when you register for a contest; or</li>
                                    <li>when you submit your personal data to us for any reason.</li>
                                </ol>
                                <p>The above does not purport to be exhaustive and sets out some common instances of when personal data about you may be collected.</p>
                                <p>2.2. When you visit, use or interact with our web application or the Platform, we may collect certain information by automated or passive means using a variety of technologies, which may be downloaded to your device and may set or modify settings on your device. The information we collect may include, without limitation, your Internet Protocol (IP) address, computer/mobile device operating system and browser type, type of mobile device, the characteristics of the mobile device, the unique device identifier (UDID) or mobile equipment identifier (MEID) for your mobile device, the address of a referring website (if any), and the pages you visit on our Platform and web applications and the times of visit. We may collect, use, disclose and/or process this information only for the Purposes (defined below).</p>
                                <p>2.3. Our Platform or web applications may collect precise information about the location of your mobile device using technologies such as GPS, Wi-Fi, etc.. We collect, use, disclose and/or process this information for one or more Purposes including, without limitation, location-based services that you request or to deliver relevant content to you based on your location or to allow you to share your location to other Users as part of the services under our web applications. For most mobile devices, you are able to withdraw your permission for us to acquire this information on your location through your device settings. If you have questions about how to disable your mobile device's location services, please contact your mobile device service provider or the device manufacturer.</p>
                                <b><li>WHAT PERSONAL DATA WILL CLEANPRO PLUS COLLECT?</li></b>
                                <p>3.1. The personal data that Cleanpro Plus may collect includes but is not limited to:</p>
                                <ul style={{ listStyleType: "disc", paddingLeft: "2rem" }}>
                                    <li style={{ listStyleType: "disc" }}>name;</li>
                                    <li style={{ listStyleType: "disc" }}>email address;</li>
                                    <li style={{ listStyleType: "disc" }}>date of birth;</li>
                                    <li style={{ listStyleType: "disc" }}>address;</li>
                                    <li style={{ listStyleType: "disc" }}>bank account and payment information;</li>
                                    <li style={{ listStyleType: "disc" }}>telephone number;</li>
                                    <li style={{ listStyleType: "disc" }}>gender;</li>
                                    <li style={{ listStyleType: "disc" }}>any other information about the User when the User signs up to use our Services or Platform, and when the User uses the Services or Platform, as well as information related to how the User uses our Services or Platform; and</li>
                                    <li style={{ listStyleType: "disc" }}>aggregate data on content the User engages with.</li>
                                </ul>
                                <p>3.2. If you do not want us to collect the aforementioned information/personal data, you may opt out at any time by notifying us in writing about it.</p>
                                <b><li>SETTING UP AN ACCOUNT</li></b>
                                <p>In order to use certain functionalities of the Services, you will have to create a user account which requires you to submit certain personal data. When you register and create an account, we require you to provide us with your name and email address as well as a username that you select. We also ask for certain information about yourself such as your telephone number, email address, photo identification, bank account details, age, date of birth and gender. Upon activating an account, you will select a username and password. Your user name and password will be used so you can securely access and maintain your account.</p>
                                <b><li>COOKIES</li></b>
                                <p>5.1. We may from time to time implement "cookies" or other features to allow us or third parties to collect or share information that will help us improve our Platform and the Services we offer, or help us offer new services and features. “Cookies” are identifiers we transfer to your computer or mobile device that allow us to recognize your computer or device and tell us how and when the Services or Platform are used or visited, by how many people and to track movements within our Platform. We may link cookie information to personal data. Cookies also link to information regarding what items you have selected for purchase and pages you have viewed. This information is used to keep track of your shopping cart, for example. Cookies are also used to deliver content specific to your interest and to monitor Platform usage.</p>
                                <p>5.2. You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this you may not be able to use the full functionality of our Platform or the Services.</p>
                                <b><li>COMMUNITY & SUPPORT</li></b>
                                <p>6.1. We provide customer service support through email, SMS and feedback forms. In order to provide customer support, we will ask for your email address and mobile phone number. We only use information received from customer support requests, including, without limitation, email addresses, for customer support services and we do not transfer to or share this information with any third parties.</p>
                                <p>6.2. You can also post questions and answer other user questions in our community forums. Our forum and messaging services allow you to participate in our community; to do so, we maintain information, such as your user ID, contact list and status messages. In addition, these and similar services in the future may require us to maintain your user ID and password.</p>
                                <b><li>HOW DO WE USE THE INFORMATION YOU PROVIDE US?</li></b>
                                <p>7.1. We may collect, use, disclose and/or process your personal data for one or more of the following purposes:</p>
                                <ol type="a">
                                    <li>to consider and/or process your application/transaction with us or your transactions or communications with third parties via the Services;</li>
                                    <li>to manage, operate, provide and/or administer your use of and/or access to our Services and our Platform, as well as your relationship and user account with us;</li>
                                    <li>to manage, operate, administer and provide you with as well as to facilitate the provision of our Services, including, without limitation, remembering your preferences;</li>
                                    <li>to tailor your experience through the Services by displaying content according to your interests and preferences, providing a faster method for you to access your account and submit information to us and allowing us to contact you, if necessary;</li>
                                    <li>to respond to, process, deal with or complete a transaction and/or to fulfil your requests for certain products and services and notify you of service issues and unusual account actions;</li>
                                    <li>to enforce our Terms of Service or any applicable end user license agreements;</li>
                                    <li>to protect personal safety and the rights, property or safety of others;</li>
                                    <li>for identification and/or verification;</li>
                                    <li>to maintain and administer any software updates and/or other updates and support that may be required from time to time to ensure the smooth running of our Services;</li>
                                    <li>to deal with or facilitate customer service, carry out your instructions, deal with or respond to any enquiries given by (or purported to be given by) you or on your behalf;</li>
                                    <li>to contact you or communicate with you via voice call, text message and/or fax message, email and/or postal mail or otherwise for the purposes of administering and/or managing your relationship with us or your use of our Services, such as but not limited to communicating administrative information to you relating to our Services. You acknowledge and agree that such communication by us could be by way of the mailing of correspondence, documents or notices to you, which could involve disclosure of certain personal data about you to bring about delivery of the same as well as on the external cover of envelopes/mail packages;</li>
                                    <li>to allow other users to interact or connect with you on the Platform, including to inform you when another User has sent you a private message or posted a comment for you on the Platform;</li>
                                    <li>to conduct research, analysis and development activities (including, but not limited to, data analytics, surveys, product and service development and/or profiling), to analyse how you use our Services, to improve our Services or products and/or to enhance your customer experience;</li>
                                    <li>to allow for audits and surveys to, among other things, validate the size and composition of our target audience, and understand their experience with Cleanpro Plus’s Services;</li>
                                    <li>where you give us your prior consent, for marketing and in this regard, to send you by various modes of communication such as postal mail, email, location-based services or otherwise, marketing and promotional information and materials relating to products and/or services (including, without limitation, products and/or services of third parties whom Cleanpro Plus may collaborate or tie up with) that Cleanpro Plus (and/or its affiliates or related corporations) may be selling, marketing or promoting, whether such products or services exist now or are created in the future.</li>
                                    <li>to respond to legal processes or to comply with or as required by any applicable law, governmental or regulatory requirements of any relevant jurisdiction, including, without limitation, meeting the requirements to make disclosure under the requirements of any law binding on Cleanpro Plus or on its related corporations or affiliates;</li>
                                    <li>to produce statistics and research for internal and statutory reporting and/or record-keeping requirements;</li>
                                    <li>to carry out due diligence or other screening activities (including, without limitation, background checks) in accordance with legal or regulatory obligations or our risk management procedures that may be required by law or that may have been put in place by us;</li>
                                    <li>to audit our Services or Cleanpro Plus’s business;</li>
                                    <li>to share your data with third party for fulfillment of your transaction with us or refunds;</li>
                                    <li>to prevent or investigate any actual or suspected violation of our Terms of Service, fraud, unlawful activity, omission or misconduct, whether relating to your use of our Services or any other matter arising from your relationship with us.</li>
                                    <li>to store, host, back up (whether for disaster recovery or otherwise) of your personal data, whether within or outside of your jurisdiction;</li>
                                    <li>to deal with and/or facilitate a business asset transaction or a potential business asset transaction, where such transaction involves Cleanpro Plus as a participant or involves only a related corporation or affiliate of Cleanpro Plus as a participant or involves Cleanpro Plus and/or any one or more of Cleanpro Plus related corporations or affiliates as participant(s), and there may be other third party organizations who are participants in such transaction. A “business asset transaction” refers to the purchase, sale, lease, merger, amalgamation or any other acquisition, disposal or financing of an organization or a portion of an organization or of any of the business or assets of an organization; and/or</li>
                                    <li>any other purposes which we notify you of at the time of obtaining your consent. (collectively, the “Purposes”).</li>
                                </ol>
                                <p>7.2 You acknowledge, consent and agree that Cleanpro may access, preserve and disclose your Account information and Content if required to do so by law or pursuant to an order of a court or by any governmental or regulatory authority having jurisdiction over Cleanpro or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) comply with a request from any governmental or regulatory authority having jurisdiction over Cleanpro; (c) enforce the Cleanpro Terms of Service or this Privacy Policy; (d) respond to any threatened or actual claims asserted against Cleanpro or other claim that any Content violates the rights of third parties; (e) respond to your requests for customer service; or (f) protect the rights, property or personal safety of Cleanpro, its users and/or the public.</p>
                                <p>7.3. As the purposes for which we will/may collect, use, disclose or process your personal data depend on the circumstances at hand, such purpose may not appear above. However, we will notify you of such other purposes at the time of obtaining your consent, unless processing of the applicable data without your consent is permitted by the Privacy Laws.</p>
                                <b><li>SHARING OF INFORMATION FROM THE SERVICES</li></b>
                                <p>Our Services enable users to share personal information with each other, in almost all occasions without Cleanpro Plus’s involvement, to complete transactions. In a typical transaction, users may have access to each other’s name, user ID, email address and other contact and postage information. Users in possession of another user’s personal data (the “Receiving Party”) must (i) comply with all applicable Privacy Laws; (ii) allow the other user (the “Disclosing Party”) to remove him/herself from the Receiving Party’s database; and (iii) allow the Disclosing Party to review what information have been collected about them by the Receiving Party.</p>
                                <b><li>HOW DOES CLEANPRO PLUS PROTECT AND RETAIN CUSTOMER INFORMATION?</li></b>
                                <p>9.1 We implement a variety of security measures and strive to ensure the security of your personal data on our systems. User personal data is contained behind secured networks and is only accessible by a limited number of employees who have special access rights to such systems However, there can inevitably be no guarantee of absolute security.</p>
                                <p>9.2 We will retain personal data in accordance with the Privacy Laws and/or other applicable laws. That is, we will destroy or anonymize your personal data as soon as it is reasonable to assume that (i) the purpose for which that personal data was collected is no longer being served by the retention of such personal data; and (ii) retention is no longer necessary for any legal or business purposes. If you cease using the Platform, or your permission to use the Platform and/or the Services is terminated or withdrawn, we may continue storing, using and/or disclosing your personal data in accordance with this Privacy Policy and our obligations under the Privacy Laws. Subject to applicable law, we may securely dispose of your personal data without prior notice to you.</p>
                                <b><li>DOES CLEANPRO PLUS DISCLOSE THE INFORMATION IT COLLECTS FROM ITS VISITORS TO OUTSIDE PARTIES?</li></b>
                                <p>10.1. In conducting our business, we will/may need to disclose your personal data to our third party service providers, agents and/or our affiliates or related corporations, and/or other third parties, for one or more of the above-stated Purposes. Such third party service providers, agents and/or affiliates or related corporations and/or other third parties would be processing your personal data either on our behalf or otherwise, for one or more of the above-stated Purposes. Such third parties include, without limitation:</p>
                                <ol type="a">
                                    <li>our subsidiaries, affiliates and related corporations;</li>
                                    <li>other users of our Platform for one or more of the above-stated Purposes</li>
                                    <li>third parties to whom disclosure by us is for one or more of the Purposes and such third parties would in turn be collecting and processing your personal data for one or more of the Purposes.</li>
                                </ol>
                                <p>10.2. This may require, among other things, sharing statistical and demographic information about our Users and their use of the Services with suppliers of advertisements and programming.</p>
                                <p>10.3. For the avoidance of doubt, in the event that Privacy Laws or other applicable laws permit an organization such as us to collect, use or disclose your personal data without your consent, such permission granted by the laws shall continue to apply.</p>
                                <p>10.4. Third parties may unlawfully intercept or access personal data transmitted to or contained on the Platform, technologies may malfunction or not work as anticipated, or someone might access, abuse or misuse information through no fault of ours. We will nevertheless deploy reasonable security arrangements to protect your personal data as required by the Privacy Laws; however there can inevitably be no guarantee of absolute security such as but not limited to when unauthorised disclosure arises from malicious and sophisticated hacking by malcontents through no fault of ours.</p>
                                <b><li>INFORMATION ON CHILDREN</li></b>
                                <p>The Services are not intended for children under the age of 13. We do not knowingly collect or maintain any personal data or non-personally-identifiable information from anyone under the age of 13 nor is any part of our Platform or other Services directed to children under the age of 13. As a parent or a legal guardian, please do not allow such children under your care to submit personal data to Cleanpro, you hereby consent to the processing of the child's personal data and accept and agree to be bound by this Policy on behalf of such child. We will close any accounts used exclusively by such children and will remove and/or delete any personal data we believe was submitted without parental consent by any child under the age of 13.</p>
                                <b><li>INFORMATION COLLECTED BY THIRD PARTIES</li></b>
                                <p>12.1. Our Platform uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are text files placed on your computer, to help the Platform analyse how Users use the Platform. The information generated by the cookie about your use of the Platform (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the Platform, compiling reports on Platform activity for Platform operators and providing other services relating to Platform activity and Internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google.</p>
                                <p>12.2. We, and third parties, may from time to time make software applications downloads available for your use on or through the Services. These applications may separately access, and allow a third party to view, your identifiable information, such as your Mobile Number, name, your user ID, your computer's IP Address or other information such as any cookies that you may previously have installed or that were installed for you by a third party software application or website. Additionally, these applications may ask you to provide additional information directly to third parties. Third party products or services provided through these applications are not owned or controlled by Cleanpro Plus. You are encouraged to read the terms and other policies published by such third parties on their websites or otherwise.</p>
                                <p>12.3 Cleanpro Plus APP use IPAY88 SDK as third party payment, which need to collect user's Information as below:</p>
                                <p>Personal data that IPAY88 may collect:</p>
                                <ol type="a">
                                    <li>Name, Age, Race, Gender, Nationality, IC Number, Passport Number, Date of Birth;</li>
                                    <li>Contact Details such as House Number, Mobile Number; Email; Home Town Address; Correspondence Address; Delivery Address; Region or State of staying;</li>
                                    <li>Credit Card details;</li>
                                    <li>Bank Account number;</li>
                                    <li>Any other information supplied by you that can indirectly or directly identify you, in order for us to carry out our contract with you.</li>
                                </ol>
                                <b><li>DISCLAIMER REGARDING SECURITY AND THIRD PARTY SITES</li></b>
                                <p>13.1. We do not guarantee the security of personal data and/or other information that you provide on third party sites. We do implement a variety of security measures to maintain the safety of your personal data that is in our possession or under our control. Your personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the personal data confidential. When you place orders or access your personal data, we offer the use of a secure server. All personal data or sensitive information you supply is encrypted into our databases to be only accessed as stated above.</p>
                                <p>13.2. In an attempt to provide you with increased value, we may choose various third party websites to link to, and frame within, the Platform. We may also participate in co-branding and other relationships to offer e-commerce and other services and features to our visitors. These linked sites have separate and independent privacy policies as well as security arrangements. Even if the third party is affiliated with us, we have no control over these linked sites, each of which has separate privacy and data collection practices independent of us. Data collected by our co-brand partners or third party web sites (even if offered on or through our Platform) may not be received by us.</p>
                                <p>13.3. We therefore have no responsibility or liability for the content, security arrangements (or lack thereof) and activities of these linked sites. These linked sites are only for your convenience and you therefore access them at your own risk. Nonetheless, we seek to protect the integrity of our Platform and the links placed upon each of them and therefore welcome any feedback about these linked sites (including, without limitation, if a specific link does not work).</p>
                                <b><li>WILL CLEANPRO PLUS TRANSFER YOUR INFORMATION OVERSEAS?</li></b>
                                <p>Your personal data and/or information may be transferred to, stored or processed outside of your country. In most cases, your personal data will be processed in Malaysia, where our servers are located and our central database is operated. Cleanpro Plus will only transfer your information overseas in accordance with Privacy Laws.</p>
                                <b><li>CONTACT US</li></b>
                                <p>15.1. If you have any questions or concerns about our privacy practices or your dealings with the Services, please do not hesitate to contact us.</p>
                                <p>15.2. If you have any complaint or grievance regarding how we are handling your personal data or about how we are complying with Privacy Laws, we welcome you to contact us with your complaint or grievance.</p>
                                <p>Please contact us with your complaint or grievance through:</p>
                                <p>E-mail: info@cleanproexpress.com or</p>
                                <p>Phone: {carelineNumber(country)}</p>
                                <p>15.3. Where it is an email or a letter through which you are submitting a complaint, your indication at the subject header that it is a Privacy Law complaint would assist us in attending to your complaint speedily by passing it on to the relevant staff in our organisation to handle. For example, you could insert the subject header as “Privacy Complaint”.</p>
                                <p>We will certainly strive to deal with any complaint or grievance that you may have fairly and as soon as possible.</p>
                                <b><li>TERMS AND CONDITIONS</li></b>
                                <p>Please also read the Terms of Service establishing the use, disclaimers, and limitations of liability governing the use of the Platform and the Services and other related policies.</p>
                            </ol>
                        </div>
                    </div>
                ) : operatorId === "rblyrj9i59p2x036jnmjdsye" ? (
                    <div className="text-center mt-4 mb-5 px-5">
                        <h3>Privacy Policy</h3>
                        <div className="text-justify">
                            <p>Thank you for visiting our site! Make Laksana Wawasan Sdn Bhd respects your privacy and is committed to protecting it through our compliance with this Privacy Policy (“Privacy Policy”). This Privacy Policy describes the types of information we may collect from users (“User” or “you”) of our website located at info.thelaundro.com and all services offered therefrom (collectively, the “Site”) and our practices for collecting, using, maintaining, protecting and disclosing that information.</p>
                            <p>This Privacy Policy applies to information we collect on the Site and in e-mail, text and other electronic messages between you and the Site. It does not apply to information collected by us offline or through any other means, or information collected by any third party, including any application or content (including advertising) that may link to or be accessible from the Site. Please read this Privacy Policy carefully to understand our practices and policies regarding your information and how we will treat it. If you do not agree with our practices and policies, your choices are not to use the Site. By accessing or using the Site, you agree to this Privacy Policy.</p>
                            <p>We will update this Privacy Policy from time to time to reflect changes in applicable law, our privacy practices, or our Site. All changes are effective 48 hours after we post them to the Site, and apply to all access to and use of the Site thereafter. You are bound by such modifications when they become effective and your continued use of the Site after the posting of modified terms constitutes your acceptance of such modifications. Although Laundro may endeavour to notify you when material changes are made to this Agreement, you should periodically review the most up-to-date version prior to using the Site.</p>
                            <p><b>Minor's Use of the Site</b></p>
                            <p>Persons under the age of 18 should use this Site only with the permission of a parent or guardian. The Site is not intended for persons under 13 years of age, and you must not use this Site if you are under 13. If we learn that a child under 13 has provided us with Personal Information (as defined below), we will delete such information from our databases.</p>
                            <p><b>Information We Collect About You and How We Collect it</b></p>
                            <p>When you register with, or use certain features of, the Site, we may collect or ask you to provide several types of information, including information by which you may be personally identified, such as name, e-mail address and phone number, and payment information, including credit card number and billing address (collectively, “Personal Information”), and information about your usage of and access to the Site. We collect information directly from you when you provide it to us, and automatically as you navigate through the Site. Information collected automatically may include usage details and information collected through cookies and other tracking technologies. We may also collect information about you from other sources, including third parties.</p>
                            <p><b>Information You Provide to Us</b></p>
                            <p>The information we collect through the Site includes Personal Information that you provide by filling in forms on the Site when registering as a User, records and copies of your correspondence (including e-mail addresses and phone numbers) if you contact us, and information you provide through communications with us or other Users on the Site. If you decline to provide requested information, we may not be able to provide certain content, functionality, services or other information offered on or through the Site (collectively, the “Services”), or you may not receive access to certain features of a Service.</p>
                            <p><b>Automatic Information Collection</b></p>
                            <p>When you navigate through and interact with the Site, we may use automatic data collection technologies to collect certain information about your browsing actions and patterns. Such information may include details of your visits to the Site, including traffic data, logs, and other communication data and resources that you access and use on the Site. We may collect information about your mobile device and internet connection, including your device’s unique device identifier, Internet Protocol (IP) address, operating system, geolocation and mobile network information, and the device’s telephone number. If you do not want us to collect this information, do not use the Site.</p>
                            <p><b>Information Collection and Tracking Technologies</b></p>
                            <p>The technologies we use for automatic information collection may include cookies, web beacons, and other tracking technologies. A cookie is a small file placed on your device. Cookies can help recognize your browser across different pages of the Site and browsing sessions and help us improve your experience on the Site by allowing us to store your information so that we recognize you when you log in to the Site and help us prevent identity theft and fraud with User authentication data. Pages of the Site and our e-mails may include small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count Users who have visited those pages or opened an e-mail, and for other related Site statistics. You may be able to block or disable cookies on your device at any time by indicating this in the preferences or options menus in your browser. You can also delete the cookies that are stored on your device. However, blocking, disabling or deleting cookies may limit your ability to view all Site content.</p>
                            <p>The Site uses Google Analytics, a web analysis service provided by Google, in order to better understand how our Users interact with the Site and to drive improvements of the User experience. Google Analytics collects information about pages visited on an app or website, how often users visit such app or website, and what other website users used prior to coming to such website. We do not combine the information collected through the use of Google Analytics with your Personal Information.</p>
                            <p>We may also use session-replay technologies such as those provided by Microsoft Clarity to capture how you use and interact with our website through behavioural metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the Microsoft Privacy Statement.</p>
                            <p><b>Information Received from Third Parties</b></p>
                            <p>We may obtain information about you from other sources, including service providers, Users and third-party services, and combine such information with information we have collected about you. To the extent we combine such third-party-sourced information with Personal Information we have collected about you, we will treat the combined information as Personal Information. We are not responsible for the accuracy of any information provided by third parties or third-party policies or practices.</p>
                            <p><b>How We Use Your Information</b></p>
                            <p><b>Site Services and Requests</b></p>
                            <p>We use information that we collect about you or that you provide to us, including any Personal Information: (i) to present the Site and its contents to you; (ii) to provide you with information, products, or Services that you request from us; (iii) to fulfil any other purpose for which you provide such information; (iv) to carry out our obligations and enforce our rights arising from any obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection, if applicable; (v) to allow you to participate in interactive features on the Site; (vi) to understand and better serve Users and improve our Services; (vii) in any other way we may describe at the point of collection; and (viii) for any other purpose with your consent.</p>
                            <p><b>Information Sharing</b></p>
                            <p>We may disclose aggregated information about our Users without restriction. We may disclose Personal Information that we collect or you provide as described in this Privacy Policy: (i) to contractors, service providers, and other third parties we use to support our business; (ii) to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us about our Users is among the assets transferred; (iii) where we believe such disclosure is appropriate to identify, combat, and address fraud or other illegal activity and to improve the security of the Site environment; (iv) to fulfil the purpose for which you provide it; (v) for any other purpose disclosed by us when you provide the information; and (vi) with your consent.</p>
                            <p>We may also disclose your Personal Information: (x) to comply with any court order, law, or legal process, including to respond to any government or regulatory request; (y) to enforce or apply terms of agreements, including for billing and collection purposes; and (z) if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of us, our customers, or others.</p>
                            <p><b>Maintenance and Retention of Personal Information</b></p>
                            <p>We take reasonable steps to help ensure that your Personal Information is maintained in a manner that is accurate, current and complete. We may retain Personal Information for as long as necessary to fulfil the purposes outlined in this Privacy Policy unless a longer or shorter period is required by applicable law.</p>
                            <p><b>Data Security</b></p>
                            <p>In order to safeguard Personal Information against unauthorized access, removal, theft or disclosure, we implement and maintain administrative, physical and technical security procedures and practices appropriate to the nature of the information. Unfortunately, in an increasingly connected digital world, no one can guarantee perfect data security, and we cannot promise that our procedures and practices will always prevent a breach of Personal Information. Should we detect a breach, we will promptly notify affected individuals, third parties and law enforcement agencies as required by applicable law.</p>
                            <p><b>Data Retention</b></p>
                            <p>We may retain your Personal Information for as long as legitimate and to the extent required to provide Services, and in accordance with the instructions of our Users who are the controllers of the Personal Information we collect and/or process. This includes, without limitation, as reasonably necessary to comply with our legal obligations and/or protect our legitimate interests, and with respect to data used for marketing activities, for as long as we have User consent therefor. We then either delete such information from our systems or anonymize it without further notice to you.</p>
                            <p><b>Users Outside the Malaysia</b></p>
                            <p>The Site is intended only for Users in the Malaysia. If you access or use the Site from outside the Malaysia, information you provide or that we obtain as a result of such use may be processed and transferred to the Malaysia, and may be subject to applicable law. By using the Site, you consent to the collection, transfer, storage, and processing of your information in and to the Malaysia.</p>
                            <p><b>Business Transfers</b></p>
                            <p>If Laksana Wawasan Sdn Bhd, or substantially all of its assets, were acquired, or in the unlikely event that Laksana Wawasan Sdn Bhd goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Laksana Wawasan Sdn Bhd may continue to use your personal information as set forth in this policy.</p>
                            <p><b>Privacy Policy Changes</b></p>
                            <p>Although most changes are likely to be minor, Laksana Wawasan Sdn Bhd may change its Privacy Policy from time to time, and in Laksana Wawasan Sdn Bhd's sole discretion. Laksana Wawasan Sdn Bhd encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
                            <p><b>Contact Us</b></p>
                            <p>If you have any questions or concerns about this Privacy Policy or our practices, or wish to exercise your rights regarding your Personal Information under applicable law, please contact us directly by email at enquiry.sabah@the-ascott.com. If you have any questions or concerns about this Privacy Policy or our practices, or wish to exercise your rights regarding your Personal Information under applicable law, please contact us directly by email at enquiry.sabah@the-ascott.com</p>
                        </div>
                    </div>
                ) : operatorId === "mzlkx7olr2yuwskuulz83srx" || operatorId === "nogedbhx7gujdy6uyty6qc73" ? (
                    <div className="text-center mt-4 mb-5 px-5">
                        <h3>Products and Pricing</h3>
                        <div style={{ textAlign: "justify" }}>
                            <p>Welcome to our self-service laundry shop! We offer a simple, efficient, and affordable laundry service to suit your needs. Below are our pricing details:</p>
                            <h5>Washing Machine</h5>
                            <p><b>RM8</b> for <b>36 minutes</b></p>
                            <p><b>Perfect for a standard wash cycle.</b></p>

                            <h5>Dryer</h5>
                            <p><b>RM8</b> for <b>40 minutes</b></p>
                            <p><b>Ideal for drying your clothes thoroughly.</b></p>

                            <h5>Additional Drying Time</h5>
                            <p><b>RM1</b> for every <b>additional 10 minutes</b></p>
                            <p><b>Extend drying time as needed for heavy or damp items.</b></p>

                            <h5>Payment Options</h5>
                            <p>We operate on a <b>fully cashless payment system</b>, which includes:</p>
                            <ul style={{ listStyleType: "disc" }}>
                                <li style={{ listStyleType: "disc" }}>E-wallets</li>
                                <li style={{ listStyleType: "disc" }}>Online banking</li>
                                <li style={{ listStyleType: "disc" }}>VISA/MASTERCARD</li>
                            </ul>

                            <p>Enjoy a hassle-free laundry experience with quick payment options, fast cycles, and convenient access in the heart of Kuala Lumpur!</p>
                        </div>
                    </div>
                ) : (
                    <div className="text-center mt-4 mb-5 px-5">
                        <h3>{laundroText()} Loyalty Privacy Policy</h3>
                        <div className="text-justify">
                            <p><small>Last updated: 31 March 2021</small></p>

                            <div className="mt-5">
                                <p>{REACT_APP_STORETYPE === "cuci" ? 'Klean And Shine Sdn. Bhd. ("we" or "us" or "our") respects the privacy of our users ("user" or "you").' : null} This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our web application (the "Application"). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIE PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.</p>
                                <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.</p>
                                <p>This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game virtual items, which may also collect and use data about you. We are not responsible for any of the data collected by any such third party.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">COLLECTION OF YOUR INFORMATION</h5>
                                <p>We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:</p>

                                <h6>Personal Data</h6>
                                <p>Demographic and other personally identifiable information (such as your name and email) that you voluntarily give to us when choosing to join as the member of {laundroText()} Loyalty to enjoy the smart laundry service and also the loyalty programme. If you choose to share data about yourself via your profile and other interactive areas of the Application, please be advised that all data you disclose in these areas will be only accessible to anyone who has organised the loyalty program and provided laundry services.</p>

                                <h6>Derivative Data</h6>
                                <p>Information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including using the laundry service, participating in the loyalty program, as well as other interactions with the Application via server log files.</p>

                                <h6>Geo-Location Information</h6>
                                <p>We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.</p>

                                <h6>Mobile Device Access</h6>
                                <p>We may request access or permission to certain features from your mobile device, including your mobile device’s bluetooth. If you wish to change our access or permissions, you may do so in your device’s settings.</p>

                                <h6>Mobile Device Data</h6>
                                <p>Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.</p>

                                <h6>Push Notifications</h6>
                                <p>We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</p>

                                <h6>Data from Contests, Giveways, and Surveys</h6>
                                <p>Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">USE OF YOUR INFORMATION</h5>
                                <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Application to:</p>
                                <ol>
                                    <li>Administer sweepstakes, promotions, and contests.</li>
                                    <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
                                    <li>Create and manage your account.</li>
                                    <li>Deliver targeted advertising, vouchers, newsletters, and other information regarding promotions and the Application to you.</li>
                                    <li>Email you regarding your account or order.</li>
                                    <li>Fulfill and manage purchases, order, payments, and other transactions related to the Application.</li>
                                    <li>Generate a personal profile about you to make future visits to the Application more personalized.</li>
                                    <li>Increase the efficiency and operation of the Application.</li>
                                    <li>Monitor and analyze usage and trends to improve your experience with the Application.</li>
                                    <li>Notify you of updates to the Application.</li>
                                    <li>Request feedback and contact you about your use of the Application.</li>
                                    <li>Resolve disputes and troubleshoot problems.</li>
                                    <li>Respond to product and customer service requests.</li>
                                    <li>Send you a newsletter.</li>
                                </ol>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">DISCLOSURE OF YOUR INFORMATION</h5>
                                <p>We may share information we have colelcted about you in certain situations. Your information may be disclosed as follows:</p>

                                <h6>By Law or to Protect Rights</h6>
                                <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.</p>

                                <h6>Third-Party Service Providers</h6>
                                <p>We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</p>

                                <h6>Marketing Communications</h6>
                                <p>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</p>

                                <h6>Affiliates</h6>
                                <p>We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</p>

                                <h6>Business Partners</h6>
                                <p>We may share your information with our business partners to offer you certain products, services or promotions.</p>

                                <h6>Other Third Parties</h6>
                                <p>We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</p>

                                <h6>Sale or Bankruptcy</h6>
                                <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.</p>
                                <p>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">TRACKING TECHNOLOGIES</h5>

                                <h6>Cookies and Web Beacons</h6>
                                <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Application. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.</p>

                                <h6>Website Analytics</h6>
                                <p>We may also partner with selected third-party vendors, such as Google Analytics to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors.</p>
                                <p>You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">SECURITY OF YOUR INFORMATION</h5>

                                <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>
                            </div>


                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">POLICY FOR CHILDREN</h5>
                                <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">CONTROLS FOR DO-NOT-TRACK FEATURES</h5>
                                <p>Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
                            </div>

                            <div className="mt-5">
                                <h5 className="text-left font-weight-bold">OPTIONS REGARDING YOUR INFORMATION</h5>

                                <h6>Account Information</h6>
                                <p>You may at any time review or change the information in your account or terminate your account by:</p>

                                <ul>
                                    <li>Logging into your account settings and updating your account</li>
                                    <li>Contacting us using the contact information provided below</li>
                                </ul>

                                <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>

                                <h6>Emails and Communications</h6>
                                <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>

                                <ul>
                                    <li>Noting your preferences at the time you register your account with the Application</li>
                                    <li>Logging into your account settings and updating your preferences.</li>
                                    <li>Contacting us using the contact information provided below</li>
                                </ul>

                                <p>If you no longer wish to receive correspondence, emails, or other communications from third parties, you are reponsible for contacting the third party directly.</p>
                            </div>

                            {
                                REACT_APP_STORETYPE === "cuci" ? (
                                    <div className="mt-5">
                                        <h5 className="text-left">CONTACT US</h5>

                                        <p>If you have questions or comments about this Privacy Policy, please contact us at: </p>

                                        <p>Klean and shine sdn bhd <br /> Lot 53, Jln utas 15/7 section 15,<br /> 40000 Shah Alam</p>
                                        <p>0129278893</p>
                                        <p>Estherliow88@gmail.com</p>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                )
            }
        </div >
    );
};

const mapStateToProps = (state) => {
    return {
        operatorId: state.user.operatorId,
        country: state.user.country
    };
};

export default connect(mapStateToProps, null)(PrivacyPolicy);